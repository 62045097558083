import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import EmployeeLeaveList from "./EmployeeLeaveList";
import CreateNewEmployeeLeave from "./CreateNewEmployeeLeave";
import Excelldownload from "./Excelldownload";
import PdfDownload from "./PdfDownload";
import ReactToPrint from "react-to-print";
import {
  Layout, Breadcrumb, Row, Col, AutoComplete,
  Divider, Button,
  DatePicker,
} from "antd";
import moment from "moment";
const { Content } = Layout;
const { RangePicker } = DatePicker;



const Maincontent = () => {
  const [updatelist, setUpdatelist] = useState(true);
  const [data, setData] = useState([]);
  const componentRef = useRef();

  const [details, setdetails] = useState([]);
  const pageno = useRef(1);
  const page_size = useRef(10);
  const start = useRef("");
  const end = useRef("");
  const keyward = useRef("");
  const [reload, setReload] = useState(true);

  const SwitchablePicker = () => {
    return (
      <Row wrap={false}>
        <Col flex="auto">
          keyword :
          <AutoComplete
            placeholder="Employee name / email"
            // onChange={onChange}
            onKeyUp={(event) => {
              keyward.current = event.target.value;
            }}
            style={{ width: "100%", minWidth: "140px" }}
          />
        </Col>
        <Col flex="auto">
          Issue date :<br></br>
          <RangePicker
            style={{ width: "100%" }}
            picker="date"
            onChange={(value) => {
              if (value) {
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
              }
            }}
          />
        </Col>


        <Col flex="auto">
          .
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={() => {
              pageno.current = 1;
              setReload(!reload);
            }} >Search</Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="site-layout-background main-frame">
        <CreateNewEmployeeLeave
          setReload={setReload}
          reload={reload}
        />
        <Excelldownload data={details} data1={details} />
        <ReactToPrint
          trigger={() => (
            <Button type="primary" style={{ marginLeft: "10px" }}>
              Print this out!
            </Button>
          )}
          content={() => componentRef.current}
        />
        <PdfDownload
          data={details}
          data1={details}
          componentRef={componentRef}
          date={moment()}
        />
        <Divider />
        {SwitchablePicker()}
        <Divider />

        <EmployeeLeaveList
          details={details}
          setdetails={setdetails}
          pageno={pageno}
          page_size={page_size}
          reload={reload}
          setReload={setReload}
          keyward={keyward}
          start={start}
          end={end}
        />
      </div>
    </>
  );
};

export default connect()(Maincontent);
