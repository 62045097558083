import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Drawer, Divider, Col, Row, Button, message, Popconfirm } from "antd";
import { Link } from "react-router-dom";

import { getAllPurchaseByContact } from "../../actions/purchase";
import { getJournalSearchResult } from "../../actions/journalAction";
import ReactToPrint from "react-to-print";
import moment from "moment";
import PaymentPrintVoucher from "./PaymentPrintVoucher";

const ContactDetails = ({
  details,
  getAllPurchaseByContact,
  getJournalSearchResult,
  businessprofile,
}) => {
  const [visible, setVisible] = useState(false);
  const [purchase_fetched, setPurchase_fetched] = useState(false);
  const [purchase_list, setPurchase_list] = useState([]);
  const [journals, setjournals] = useState([]);
  const due = useRef(0);
  const bill = useRef(0);
  const paid = useRef(0);
  const payment = useRef(0);
  const balance = useRef(0);
  const componentRef = useRef();
  var formatter = new Intl.NumberFormat("en-IN");

  const showDrawer = () => {
    setjournals([]);
    setPurchase_fetched(false);
    payment.current = 0;
    balance.current = 0;
    setVisible(true);
    getJournalSearchResult(
      "",
      "",
      "",
      "",
      "",
      "",
      details.purchase_number,
      ""
    ).then((result) => {
      setjournals(result.reverse());
      setPurchase_fetched(true);
    });
    // setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Link to="#" onClick={showDrawer} key={details.id}>
        History
      </Link>

      <Drawer
        width="70%"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <ReactToPrint
          trigger={() => <Button type="primary">Print this out!</Button>}
          content={() => componentRef.current}
        />
        <div ref={componentRef} style={{ padding: "10px" }}>
          <Row
            style={{
              borderBottom: "2px solid lightgray",
              paddingBottom: "5px",
              marginBottom: "20px",
            }}
          >
            <Col span={17} style={{ paddingTop: "10px" }}>
              <small>
                <div
                  style={{ lineHeight: "2.5px" }}
                  dangerouslySetInnerHTML={{
                    __html: businessprofile.address,
                  }}
                ></div>
              </small>
            </Col>

            <Col span={7} style={{ textAlign: "right" }}>
              <img
                src={businessprofile.logo}
                style={{
                  maxHeight: "60px",
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              {details.Contact ? (
                <>
                  {details.Contact[0].name}
                  <br></br>
                  {details.Contact[0].address}
                  <br></br>
                  {details.Contact[0].phone}
                  <br></br>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              PO no. : {details.purchase_number}
            </Col>
          </Row>

          <br></br>

          {purchase_fetched ? (
            <>
              <h3>Transaction History</h3>
              <table className="history_table">
                <tr>
                  {/* <td>SL.</td> */}
                  <td>Date</td>
                  <td>Head</td>
                  <td>Details</td>
                  <td style={{ textAlign: "right" }}>Amount</td>
                  {/* <td style={{ textAlign: "right" }}>Balance</td> */}
                  <td>Voucher</td>
                </tr>

                {journals.map((item, index) => {
                  if (item.Subgroup == "Cash" || item.Subgroup == "Bank") {
                    if (item.increase) {
                      balance.current += parseFloat(item.amount);
                    } else {
                      balance.current -= parseFloat(item.amount);
                    }

                    return (
                      <>
                        <tr>
                          {/* <td>{index + 1}</td> */}
                          <td>{moment(item.created).format("DD-MM-YYYY")}</td>
                          <td>{item.Subgroup}</td>
                          <td>{item.details}</td>
                          <td style={{ textAlign: "right" }}>
                            {item.increase ? (
                              formatter.format(item.amount)
                            ) : (
                              <>( {formatter.format(item.amount)} )</>
                            )}
                          </td>
                          {/* <td style={{ textAlign: "right" }}>
                          {formatter.format(balance.current)}
                        </td> */}
                          <td>
                            {
                              <PaymentPrintVoucher
                                details={details}
                                journal={item}
                              />
                            }
                          </td>
                        </tr>
                      </>
                    );
                  }
                })}
              </table>
              <Divider />

              <h3>Balance History</h3>
              <table className="history_table">
                <tr>
                  {/* <td>SL.</td> */}
                  <td>Date</td>
                  <td>Head</td>
                  <td>Details</td>
                  <td style={{ textAlign: "right" }}>Amount</td>
                  <td style={{ textAlign: "right" }}>Balance</td>
                  {/* <td>Due Amount</td> */}
                </tr>

                {journals.map((item, index) => {
                  if (
                    (item.print[0].account_code == 100010003 ||
                      item.print[0].account_code == 200010050 ||
                      item.print[0].account_code == 200010011 ||
                      item.print[0].account_code == 100010010) &&
                    item.amount > 0
                  ) {
                    if (item.increase) {
                      payment.current += parseFloat(item.amount);
                    } else {
                      payment.current -= parseFloat(item.amount);
                    }

                    return (
                      <>
                        <tr>
                          {/* <td>{index + 1}</td> */}
                          <td>{moment(item.created).format("DD-MM-YYYY")}</td>
                          <td>{item.Subgroup}</td>
                          <td>{item.details}</td>
                          <td style={{ textAlign: "right" }}>
                            {item.increase ? (
                              formatter.format(item.amount)
                            ) : (
                              <>( {formatter.format(item.amount)} )</>
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {formatter.format(payment.current)}
                          </td>
                          {/* <td>{item.due}</td> */}
                        </tr>
                      </>
                    );
                  }
                })}
              </table>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </Drawer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
  };
};

export default connect(mapStateToProps, {
  getAllPurchaseByContact,
  getJournalSearchResult,
})(ContactDetails);
