import * as types from "../types";

const INITIAL_STATE = { taglist: [], specifictaglist: [] };

const tagReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CREATE_TAG:
      return state;

    case types.GET_ALL_TAG:
      return { ...state, taglist: action.payload };

    case types.GET_SPECIFIC_TAG:
      return { ...state, specifictaglist: action.payload };

    case types.DELETE_TAG:
      const taglist = state.taglist.filter((TAG) => TAG.id !== action.payload);
      return { ...state, taglist };

    default:
      return state;
  }
};

export default tagReducer;
