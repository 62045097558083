import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllTag } from "../../../actions/tagAction";
import Rendertable from "./Rendertable";

const List = ({ getAllTag, List, updatelist, setUpdatelist }) => {
  useEffect(() => {
    getAllTag();
    setUpdatelist(true);
  }, [updatelist, setUpdatelist]);

  return (
    <>
      <Rendertable List={List} setUpdatelist={setUpdatelist} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    List: state.tag.taglist,
  };
};

export default connect(mapStateToProps, {
  getAllTag,
})(List);
