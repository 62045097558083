import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import List from "./List";
import CreateNewChart from "./CreateNewChart";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Select,
  Button,
  AutoComplete,
  Skeleton,
  Divider,
} from "antd";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Index = ({}) => {
  const [reload, setreload] = useState(false);
  const start = useRef("");
  const end = useRef("");
  const keyward = useRef("");
  const pageno = useRef(1);
  const page_size = useRef(10);

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
      setreload(!reload);
    }
  };

  const SwitchablePicker = () => {
    return (
      <Row wrap={false}>
        <Col flex="auto">
          keyword :
          <AutoComplete
            placeholder="input search text"
            // onChange={onChange}
            onKeyUp={onChange}
            style={{ width: "100%", minWidth: "140px" }}
          />
        </Col>
        <Col flex="auto">
          Issue date :<br></br>
          <RangePicker
            style={{ width: "100%" }}
            picker="date"
            onChange={(value) => {
              if (value) {
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                setreload(!reload);
              }
            }}
          />
        </Col>

        <Col flex="auto">
          <Button
            type="primary"
            style={{ float: "right", marginBottom: "10px" }}
          >
            <Link to="/accounting/journalvoucher/create">
              Create new journal voucher
            </Link>
          </Button>
        </Col>
      </Row>
    );
  };

  const renderview = () => {
    return (
      <>
        {SwitchablePicker()}
        <Divider />
        <Row>
          <Col span={24}>
            <List
              reload={reload}
              setreload={setreload}
              pageno={pageno}
              page_size={page_size}
              start={start}
              end={end}
              keyward={keyward}
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Accounting</Breadcrumb.Item>
              <Breadcrumb.Item>Journal voucher</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              {renderview()}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default connect(null)(Index);
