import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import EmployeeSalaryList from "./EmployeeSalaryList";
import CreateNewEmployeeSalary from "./CreateNewEmployeeSalary";
import {
  Layout, Breadcrumb, Row, Col, AutoComplete,
  Divider, Button,
  DatePicker,
} from "antd";
import moment from "moment";
const { Content } = Layout;
const { RangePicker } = DatePicker

const Maincontent = () => {
  const [updatelist, setUpdatelist] = useState(true);
  const [details, setdetails] = useState([]);
  const pageno = useRef(1);
  const page_size = useRef(10);
  const keyward = useRef("");
  const [reload, setReload] = useState(true);

  const SwitchablePicker = () => {
    return (
      <Row wrap={false}>
        <Col flex="auto">
          keyword :
          <AutoComplete
            placeholder="Employee name / email"
            // onChange={onChange}
            onKeyUp={(event) => {
              keyward.current = event.target.value;
            }}
            style={{ width: "100%", minWidth: "140px" }}
          />
        </Col>
        <Col flex="auto">
          .
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={() => {
              pageno.current = 1;
              setReload(!reload);
            }} >Search</Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="site-layout-background main-frame">
        <CreateNewEmployeeSalary
          setReload={setReload}
          reload={reload}
        />
        <Divider />
        {SwitchablePicker()}
        <Divider />
        <EmployeeSalaryList
          details={details}
          setdetails={setdetails}
          pageno={pageno}
          page_size={page_size}
          reload={reload}
          setReload={setReload}
          keyward={keyward}
        />
      </div>
    </>
  );
};

export default connect()(Maincontent);
