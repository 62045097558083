import { Skeleton } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getContactSearchResultP } from "../../actions/contactAction";

import Rendertable from "./Rendertable";

const ContactList = ({
  getContactSearchResultP,
  List,
  updatelist,
  setUpdatelist,
  pageno,
  page_size,
  keyward,
  start,
  end,
  role,
}) => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    getContactSearchResultP(
      keyward.current,
      "Customer",
      role.current,
      start.current,
      end.current,
      pageno.current,
      page_size.current
    ).then((result) => {
      setdata(result);
      setloading(false);
    });
  }, [updatelist]);

  if (loading) {
    return <Skeleton active />;
  } else
    return (
      <>
        {
          <Rendertable
            List={List}
            updatelist={updatelist}
            setUpdatelist={setUpdatelist}
            pageno={pageno}
            page_size={page_size}
          />
        }
      </>
    );
};

const mapStateToProps = (state) => {
  return {
    List: state.contacts.contactlistsearchresult,
  };
};

export default connect(mapStateToProps, {
  getContactSearchResultP,
})(ContactList);
