import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
// import Maincontent from "./Maincontent";
import { getSpecificpage } from "../../../actions/ecommercepageAction";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Spin,
  Input,
  Form,
  Divider,
  Button,
} from "antd";
const { Content } = Layout;

const PageDetails = ({
  getSpecificpage,
  page,
  selectedfeature,
  reloadpage,
  setreloadpage,
}) => {
  const [reloadfeature, setreloadfeature] = useState(false);
  const [loadingpage, setloadingpage] = useState(true);
  const [datas, setdatas] = useState([]);

  useEffect(() => {
    setloadingpage(true);
    getSpecificpage(page.id).then((res) => {
      setdatas(res);
      setloadingpage(false);
    });
  }, [reloadpage]);

  const renderData = () => {
    return datas?.features.map((data) => {
      return (
        <Button
          onClick={() => {
            selectedfeature = data;
            setreloadpage(!reloadpage);
          }}
        >
          {data.name}
        </Button>
      );
    });
  };
  if (loadingpage) {
    return <>loading...........</>;
  } else
    return (
      <>
        <Row>
          <Col span={24}>
            Page Features
            <Divider />
            <Spin spinning={loadingpage}>{renderData()}</Spin>
          </Col>
        </Row>
      </>
    );
};

export default connect(null, { getSpecificpage })(PageDetails);
