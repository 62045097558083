import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar";
import Navbar from "../layout/Navbar";
import { getAllServices, getAllServicesP } from "../../actions/invoiceItem";
import { getAllEmployee } from "../../actions/employeeAction";
import RenderServiceTable from "./RenderServiceTablefromservice";

import {
  Divider,
  Row,
  Col,
  Affix,
  Button,
  message,
  Space,
  AutoComplete,
  DatePicker,
  Select,
  Spin,
} from "antd";

import { Layout, Breadcrumb } from "antd";
const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Option } = Select;

const Index = ({
  getAllServices,
  getAllServicesP,
  getAllEmployee,
  employeeList,
}) => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [loadServicepage, setloadServicepage] = useState(false);
  const [type, setType] = useState("date");
  const start = useRef("");
  const end = useRef("");
  const deliverystart = useRef("");
  const deliveryend = useRef("");
  const keyward = useRef("");
  const employe = useRef("");
  const realter = useRef("");
  const status = useRef("");
  const pageno = useRef(1);
  const page_size = useRef(10);

  useEffect(() => {
    getAllEmployee();
  }, []);

  useEffect(() => {
    setloading(true);
    getAllServicesP(
      start.current,
      end.current,
      keyward.current,
      pageno.current,
      page_size.current,
      employe.current,
      realter.current,
      status.current,
      deliverystart.current,
      deliveryend.current,
    ).then(function (result) {
      setData(result);
      setloading(false);
    });
  }, [loadServicepage]);

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
      setloadServicepage(!loadServicepage);
    }
  };

  const SwitchablePicker = () => {
    return (
      <Row>
        <Col span={4}>
          <h3>Keyword :</h3>
          <AutoComplete
            placeholder="input search text"
            // onChange={onChange}
            onKeyUp={onChange}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={4}>
          <h3>Issue date :</h3>
          <RangePicker
            picker={type}
            style={{ width: "100%" }}
            onChange={(value) => {
              if (value) {
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                setloadServicepage(!loadServicepage);
              }
            }}
          />
        </Col>
        <Col span={4}>
          <h3>Delivery date :</h3>
          <RangePicker
            picker={type}
            style={{ width: "100%" }}
            onChange={(value) => {
              if (value) {
                deliverystart.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                deliveryend.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                setloadServicepage(!loadServicepage);
              }
            }}
          />
        </Col>
        <Col span={4}>
          <h3>Employee :</h3>
          <Select
            showSearch
            placeholder="Please choose an assignee"
            style={{ fontWeight: "400", width: "100%" }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              employe.current = value;
              setloadServicepage(!loadServicepage);
            }}
          >
            <Option value="">All</Option>;
            {employeeList.map((employee) => {
              if (employee.branchName == "Factory") {
                return <Option value={employee.id}>{employee.name}</Option>;
              }
            })}
          </Select>
        </Col>
        <Col span={4}>
          <h3>Status :</h3>
          <Select
            style={{ width: "100%" }}
            placeholder="Status"
            onChange={(value) => {
              status.current = value;
              setloadServicepage(!loadServicepage);
            }}
          >
            <Option value="">All</Option>
            <Option value="Pending">Pending</Option>
            <Option value="Received by factory">Received by factory</Option>
            <Option value="On Hold">On Hold</Option>
            <Option value="Processing">Processing</Option>
            <Option value="Partially Ready">Partially Ready</Option>
            <Option value="Ready (QC checked)">Ready (QC checked)</Option>
            <Option value="Outlet Delivered">Outlet Delivered</Option>
          </Select>
        </Col>
        <Col span={4}>
          <h3>Alter Type :</h3>
          <Select
            showSearch
            placeholder=""
            style={{ fontWeight: "400", width: "100%" }}
            optionFilterProp="children"
            onChange={(value) => {
              realter.current = value;
              setloadServicepage(!loadServicepage);
            }}
          >
            <Option value="">All</Option>;
            <Option value="true">Re-altered</Option>;
            <Option value="false">Not re-altered</Option>;
          </Select>
        </Col>
        <Col span={4}>
          <h3></h3>
        </Col>

      </Row>
    );
  };
  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>All Orders</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-background main-frame">
              {SwitchablePicker()}
              <Divider />
              <Spin spinning={loading}>
                <RenderServiceTable
                  List={data}
                  setloadServicepage={setloadServicepage}
                  pageno={pageno}
                  page_size={page_size}
                  loadServicepage={loadServicepage}
                  setloading={setloading}
                />
              </Spin>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelist,
  };
};

export default connect(mapStateToProps, {
  getAllServices,
  getAllServicesP,
  getAllEmployee,
})(Index);
