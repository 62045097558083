import { Skeleton, Card, List } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getAllimage } from "../../../actions/ecommerceimageAction";

import Rendertable from "./Rendertable";

const ContactList = ({
  getAllimage,
  updatelist,
  setselectedimage,
  setUpdatelist,
  pageno,
  page_size,
  keyward,
  start,
  end,
  role,
}) => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    getAllimage().then((result) => {
      console.log(result);
      setdata(result);
      setloading(false);
    });
  }, [updatelist]);

  if (loading) {
    return <Skeleton active />;
  } else
    return (
      <>
        <List
          grid={{
            gutter: 6,
            column: 8,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                setselectedimage(item);
              }}
            >
              {/* <Card> */}
              <img src={item.thumbnail} style={{ width: "100%" }} />
              {/* </Card> */}
            </List.Item>
          )}
        />
      </>
    );
};

export default connect(null, {
  getAllimage,
})(ContactList);
