import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Drawer,
  Divider,
  Col,
  Row,
  Button,
  message,
  Popconfirm,
  Space,
  Tag,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { deleteTag } from "../../../actions/tagAction";
import Edit from "./Edit";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const Details = ({ details, deleteAttribute, setUpdatelist, deleteTag }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showcolor = (attribute, colorr) => {
    if (attribute.name == "Color") {
      return <Tag color={colorr}>{colorr}</Tag>;
    } else {
      return <></>;
    }
  };

  const confirm = () => {
    deleteTag(details.id);
    setVisible(false);
    setUpdatelist(false);
    message.success(details.name + " Has been deleted from your tag list");
  };
  return (
    <>
      <Link to="#" onClick={showDrawer} key={details.id}>
        View Details
      </Link>

      <Drawer
        width={640}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          Information
        </p>
        <p className="site-description-item-profile-p">Attribute</p>
        <Row>
          <Col span={12}>
            <DescriptionItem title="name" content={details.name} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Slug" content={details.slug} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Meta title" content={details.meta_title} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Meta description"
              content={details.meta_description}
            />
          </Col>
        </Row>

        <Button danger style={{ marginRight: "10px" }}>
          <Popconfirm
            title="Are you sure to delete this tag?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Link to="#">Delete</Link>
          </Popconfirm>
        </Button>
        <Edit details={details} setUpdatelist={setUpdatelist} />
        <Divider />
        <p className="site-description-item-profile-p">Terms</p>
      </Drawer>
    </>
  );
};

export default connect(null, { deleteTag })(Details);
