import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excelldownload = ({ data, data1 }) => {
  return (
    <ExcelFile
      element={
        <Button type="primary" style={{ width: "100%" }}>
          Download
        </Button>
      }
    >
      <ExcelSheet data={data} name="Sales person report">
        <ExcelColumn label="name" value="name" />
        <ExcelColumn label="Invoice" value="invoice" />
        <ExcelColumn label="Amount" value="amount" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Excelldownload;
