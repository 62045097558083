import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Divider,
  Skeleton,
  Card,
  Button,
  AutoComplete,
  Input,
  Spin,
} from "antd";

import { getAlluserlogPagination } from "../../../actions/userlogAction";
import Rendertable from "./Rendertable";
import Excelldownload from "./Excelldownload";
import Log from "./Log";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const List = ({ getAlluserlogPagination }) => {
  const [data, setdata] = useState();
  const [loading, setloading] = useState(false);
  const pageno = useRef(1);
  const page_size = useRef(10);
  const start = useRef("");
  const end = useRef("");
  const keyword = useRef("");
  const content_type = useRef("");
  const action_type = useRef("");
  const [type, setType] = useState("date");
  const [reload, setreload] = useState(false);

  // useEffect(() => {
  //   getAlluserlogPagination(pageno.current, page_size.current).then(
  //     (result) => {
  //       setdata(result);
  //       setloading(false);
  //     }
  //   );
  // }, [reload, pageno.current, page_size.current]);

  const SwitchablePicker = () => {
    return (
      <Row wrap={false}>
        <Col flex="auto">
          Date :
          <RangePicker
            showTime
            style={{ width: "100%" }}
            picker={type}
            onChange={(value) => {
              if (value) {
                // start.current = value[0].format("YYYY-MM-DDTHH:MM:SS") + "T00:00:00";
                // end.current = value[1].format("YYYY-MM-DDTHH:MM:SS") + "T23:59:59";
                start.current = value[0].format("YYYY-MM-DDTHH:mm:ss");
                end.current = value[1].format("YYYY-MM-DDTHH:mm:ss");
                // alert(start.current);
                setreload(!reload);
              } else {
                start.current = "";
                end.current = "";
                setreload(!reload);
              }
            }}
          />
        </Col>
        <Col flex="auto">
          keyword :
          <AutoComplete
            placeholder="input search text"
            // onChange={onChange}
            onKeyUp={(value) => {
              keyword.current = value.target.value;
              setreload(!reload);
            }}
            style={{ width: "100%", minWidth: "140px" }}
          />
        </Col>
        <Col flex="auto">
          Type
          <Select
            placeholder="Select an Account"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
            onChange={(value) => {
              if (value != "") {
                content_type.current = value;
                setreload(!reload);
              } else {
                content_type.current = "";
                setreload(!reload);
              }
            }}
          >
            <Option value="">All</Option>
            <Option value="19">product</Option>
            <Option value="20">Product variation</Option>
            <Option value="28">Invoice</Option>
            <Option value="37">Invoice Item</Option>
          </Select>
        </Col>
        <Col flex="auto">
          Action type
          <Select
            placeholder="Select an Account"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
            onChange={(value) => {
              if (value != "") {
                action_type.current = value;
                setreload(!reload);
              } else {
                action_type.current = "";
                setreload(!reload);
              }
            }}
          >
            <Option value="">All</Option>
            <Option value="delete">Delete</Option>
            <Option value="create">Create</Option>
            <Option value="update">Update</Option>
          </Select>
        </Col>
      </Row>
    );
  };

  if (loading) {
    return <Skeleton active />;
  } else {
    return (
      <>
        {/* <Excelldownload data={data.results} /> */}
        {/* <Rendertable
          List={data}
          pageno={pageno}
          page_size={page_size}
          setreload={setreload}
          setloading={setloading}
          reload={reload}
        /> */}
        {/* {data.results.map((log) => {
          return <>{log.action}</>;
        })} */}
        {SwitchablePicker()}
        <Divider />
        <Row>
          <Col span="24">
            <Log
              ID=""
              action_type={action_type.current}
              start={start.current}
              end={end.current}
              reload={reload}
              setreload={setreload}
              keyword={keyword.current}
              content_type={content_type.current}
            />
          </Col>
          {/* <Col span="7" offset={1}>
            <Log
              ID=""
              action_type="update"
              start={start.current}
              end={end.current}
              reload={reload}
              setreload={setreload}
              keyword={keyword.current}
              content_type={content_type.current}
            />
          </Col>
          <Col span="7" offset={1}>
            <Log
              ID=""
              action_type="delete"
              start={start.current}
              end={end.current}
              reload={reload}
              setreload={setreload}
              keyword={keyword.current}
              content_type={content_type.current}
            />
          </Col> */}
        </Row>
      </>
    );
  }
};

export default connect(null, {
  getAlluserlogPagination,
})(List);
