import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excelldownload = ({ data, data1, startDate, endDate }) => {
  var formatter = new Intl.NumberFormat("en-IN");
  let fileName = "StockValuationReport:" + startDate + "-" + endDate;
  return (
    <ExcelFile element={<button>Download</button>} filename={fileName}>
      <ExcelSheet data={data} name="Stock Valuation Report}">
        <ExcelColumn label="ID" value="id" />
        <ExcelColumn label="Product Barcode" value="barcode" />
        <ExcelColumn label="Product Name" value="title" />
        <ExcelColumn label="Category" value={(col) => col.main_category} />
        <ExcelColumn label="Sub Category" value={(col) => col.category_name} />

        <ExcelColumn label="Color" value="Color" />
        <ExcelColumn label="Size" value="Size" />
        <ExcelColumn label="Location" value="Location" />
        <ExcelColumn label="Stock Alert Amount" value="stock_alart_amount" />
        <ExcelColumn label="Quantity" value="quantity" />
        <ExcelColumn
          label="Cost of Goods Sold"
          value={(col) => col.purchase_price}
        />
        <ExcelColumn label="Price" value={(col) => col.selling_price} />

        <ExcelColumn
          label="Total Cost of Goods Sold"
          value={(col) => col.purchase_price * col.quantity}
        />
        <ExcelColumn
          label="Total Price"
          value={(col) => col.selling_price * col.quantity}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Excelldownload;
