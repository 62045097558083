import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";

import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Button,
  Col,
  Row,
  Select,
  message,
  TreeSelect,
  Divider,
  Drawer,
} from "antd";

const { Option } = Select;

const BarcodePrinter = ({ details, variation }) => {
  const wrapper_ref = React.useRef();
  const componentRef = useRef();

  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "BDT",
  });

  const printt = () => {
    const opt = {
      scale: 4,
    };

    const elem = wrapper_ref.current;
    html2canvas(elem, opt).then((canvas) => {
      const iframe = document.createElement("iframe");
      iframe.name = "printf";
      iframe.id = "printf";
      iframe.height = 0;
      iframe.width = 0;
      document.body.appendChild(iframe);

      const imgUrl = canvas.toDataURL({
        format: "jpeg",
        quality: "1.0",
      });
      let page = "";

      console.log(details);
      if (
        details.parent_category != "FABRIC" ||
        details.parent_category != "MATERIAL" ||
        details.parent_category != "PACKAGING"
      ) {
        for (var i = 1; i <= variation.quantity; i++) {
          if (variation.quantity != i) {
            page += `<div style="min-height: 93.8vh;text-align: center; font-size: 8px; border-bottom: 1px solid white">
            <div style="padding: 0px; ">
            <h3 style="margin-top: 20px;margin-bottom: 10px; font-size: 10px">ANZARA BANGLADESH LTD.</h3>
           
            <h4 style="margin: 0px; margin-bottom: 10px; font-size: 8px">${
              details.breadcrumbs
                ? details.breadcrumbs.length > 1
                  ? details.breadcrumbs[details.breadcrumbs.length - 2].name
                  : details.breadcrumbs[details.breadcrumbs.length - 1].name
                : ""
            }</h4>
            
            <img width="100%" src="${imgUrl}"/>
           
            <h4 style="margin: 0px; margin-bottom: 10px; font-size: 10px">${
              details.title
            }-${i}</h4>
           
            <p style="margin-top: 10px;margin-bottom: 10px;">${
              variation.color ? variation.color : ""
            }</p>
            
            <h3 style="margin-top: 1px;margin-bottom: 1px;font-size: 10px">${formatter.format(
              variation.selling_price
            )}</h3>
            <h4 style="margin-top: 1px;margin-bottom: 1px;">(VAT inclusive)</h4>
            <h4 style="margin-top: 20px;margin-bottom: 20px;">**Dry wash only</h4>
            </div></div>`;
          } else {
            page += `<div style="min-height: 80vh;text-align: center; font-size: 8px; border-bottom: 1px solid white">
        <div style="padding: 0px; ">
        <h3 style="margin-top: 20px;margin-bottom: 10px; font-size: 10px">ANZARA BANGLADESH LTD.</h3>
        <h4 style="margin: 0px; margin-bottom: 10px; font-size: 8px">${
          details.breadcrumbs
            ? details.breadcrumbs.length > 1
              ? details.breadcrumbs[details.breadcrumbs.length - 2].name
              : details.breadcrumbs[details.breadcrumbs.length - 1].name
            : ""
        }</h4>
        
        
        <img width="100%" src="${imgUrl}"/>
        <h4 style="margin-top: 10px;margin-bottom: 10px;">${
          variation.color ? variation.color : ""
        }</h4>
       
        <h4 style="margin: 0px; margin-bottom: 10px; font-size: 10px">${
          details.title
        }-${i}</h4>
        
        
        <h3 style="margin-top: 1px;margin-bottom: 1px;font-size: 10px">${formatter.format(
          variation.selling_price
        )}</h3>
        <h4 style="margin-top: 1px;margin-bottom: 1px;">(VAT inclusive)</h4>
        <h4 style="margin-top: 20px;margin-bottom: 20px;">**Dry wash only</h4>
        </div></div>`;
          }
        }
      } else {
        page += `<div style="min-height: 80vh;text-align: center; font-size: 8px">
        <div style="padding: 0px; ">
        <h3 style="margin-top: 20px;margin-bottom: 10px; font-size: 10px">ANZARA BANGLADESH LTD.</h3>
        <h4 style="margin: 0px; margin-bottom: 10px; font-size: 8px">${
          details.breadcrumbs
            ? details.breadcrumbs.length > 1
              ? details.breadcrumbs[details.breadcrumbs.length - 2].name
              : details.breadcrumbs[details.breadcrumbs.length - 1].name
            : ""
        }</h4>
        
        <img width="100%" src="${imgUrl}"/>

        <h4 style="margin-top: 10px;margin-bottom: 10px;">${
          variation.color ? variation.color : ""
        }</h4>
       
        <h4 style="margin: 0px; margin-bottom: 10px; font-size: 10px">${
          details.title
        }</h4>
        
        
        <h3 style="margin-top: 1px;margin-bottom: 1px;font-size: 10px">${formatter.format(
          variation.selling_price
        )}</h3>
        <h4 style="margin-top: 1px;margin-bottom: 1px;">(VAT inclusive)</h4>
        <h4 style="margin-top: 20px;margin-bottom: 20px;">**Dry wash only</h4>
        </div></div>`;
      }
      var newWin = window.frames["printf"];
      newWin.document.write(
        `<body onload="window.print()"><style>
        @page { size: auto;  margin: 0mm; }
        </style><table>${page}<table></body>`
      );
      newWin.document.close();
      return page;
    });
  };

  // <h4 style="margin: 0px; font-size: 20px">${
  //   details.product_code
  // }-${i}</h4>
  return (
    <>
      <div ref={wrapper_ref}>
        <Barcode value={variation.barcode} />
      </div>
      <Button onClick={printt}>Print Barcode</Button>
    </>
  );
};

export default connect()(BarcodePrinter);
