import * as types from "../types";
import backend from "../api/api";
import history from "../history";
import { store } from "../store";
import { message } from "antd";

const getConfig = () => {
  const token = store.getState().auth.token;

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return config;
};

export const createimage = (formValues) => async (dispatch) => {
  try {
    const response = await backend.post(
      `api/ecommerce/image/`,
      formValues,
      getConfig()
    );
    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    alert(error);
  }
};

export const getAllimage = () => async (dispatch) => {
  try {
    const response = await backend.get("api/ecommerce/image/", getConfig());
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSpecificimage = (id) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/ecommerce/image/${id}/`,
      getConfig()
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateimage = (id, formValues) => async (dispatch) => {
  try {
    const response = await backend.put(
      `api/ecommerce/image/${id}/`,
      formValues,
      getConfig()
    );
    if (response.status === 202) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteimage = (id) => async (dispatch) => {
  try {
    const response = await backend.delete(
      `api/ecommerce/image/${id}/`,
      getConfig()
    );
    if (response.status === 204) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};
