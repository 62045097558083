import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar";
import Navbar from "../layout/Navbar";
import { getAllTransfer } from "../../actions/transfer";

import RenderTable from "./RenderTable";

import { Layout, Breadcrumb, Row, Col } from "antd";
const { Content } = Layout;

const ProductDetails = ({ getAllTransfer, Auth }) => {
  const [details, setdetails] = useState([]);
  const pageno = useRef(1);
  const page_size = useRef(20);
  const [reload, setReload] = useState(true);
  useEffect(() => {
    getAllTransfer(pageno.current, page_size.current).then(function (result) {
      console.log(result);
      setdetails(result);
    });
  }, [reload]);

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Transfer</Breadcrumb.Item>
              <Breadcrumb.Item>All</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              <RenderTable
                details={details}
                Auth={Auth}
                pageno={pageno}
                page_size={page_size}
                reload={reload}
                setReload={setReload}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, { getAllTransfer })(ProductDetails);
