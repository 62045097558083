import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { getAllAccount } from "../../../actions/accountsAction";
import {
  getJournalSearchResult,
  getJournalSearchResultwithinvoice,
} from "../../../actions/journalAction";

import moment from "moment";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Divider,
  Skeleton,
  Card,
  Spin,
  Collapse,
} from "antd";
const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const CashRegister = ({
  getJournalSearchResult,
  getJournalSearchResultwithinvoice,
  getAllAccount,
  Auth,
  location,
  // loading,
  // setloading,
  reload,
  setreload,
  start,
  end,
  collectionType,
  total_data,
}) => {
  var inovicecount = 0;
  var currentdate = new Date();
  const [loading, setloading] = useState(true);
  const [List, setList] = useState([]);
  let total_payments = useRef([]);
  var formatter = new Intl.NumberFormat("en-IN");
  const cash = useRef(0);
  const bank = useRef(0);
  const mobile = useRef(0);
  const [accounts, setaccounts] = useState([]);
  const [journals, setjournals] = useState([]);
  const [outletlist, setoutletlist] = useState([]);
  const totalcash = useRef(0);
  const totalpetty = useRef(0);

  const current_due_collection = useRef(0);

  const now = moment();
  const isBetween = now.isBetween(start.current, end.current);
  console.log(isBetween);

  useEffect(() => {
    setloading(true);
    getAllAccount().then((result) => {
      setaccounts(result);
    });
    if (start.current != "" && end.current != "") {
      getJournalSearchResultwithinvoice(
        "",
        start.current,
        end.current,
        "",
        "1000"
      ).then((result) => {
        totalcash.current = 0;
        current_due_collection.current = 0;
        setjournals(result);
        setloading(false);
      });
    } else {
      setloading(false);
    }
  }, [reload]);

  const Accounts_render_previous_due = (account) => {
    let total = 0;
    let filtereditems = [];
    for (let i = 0; i < journals.length; i++) {
      if (
        journals[i].account == account.id &&
        journals[i].invoice > 0 &&
        journals[i].Group == "Assets" &&
        journals[i].details.includes("Sell with invoice no:") &&
        moment(journals[i].invoiceDate).format("DD-MM-YYYY") !=
        moment(journals[i].created).format("DD-MM-YYYY") &&
        !moment(journals[i].invoiceDate, "YYYY-MM-DDTHH:mm:ss").isBetween(
          moment(start.current),
          moment(end.current)
        )
      ) {
        if (journals[i].increase) {
          filtereditems.push(journals[i]);
        }
        if (journals[i].outlet == location.current) {
          if (journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            // total -= parseFloat(journals[i].amount);
          }
        } else if (location.current == "") {
          if (journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            // total -= parseFloat(journals[i].amount);
          }
        }
      }
    }
    // totalcash.current += parseFloat(total);
    if (total > 0) {
      return (
        <>
          <Panel
            header={<b>{account.name}</b>}
            key={account.id}
            extra={<b>{formatter.format(parseFloat(total).toFixed(2))}</b>}
            showArrow={false}
            style={{ border: "none" }}
          >
            <table className="history_table" style={{ width: "100%" }}>
              <tr>
                <td>Group</td>
                <td>Sub Group</td>
                <td>Particulars</td>
                <td>Type</td>
                <td>Amount</td>
              </tr>
              {filtereditems.map((journal) => {
                if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  journal.outlet == location.current
                ) {
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                } else if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  location.current == ""
                ) {
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                }
              })}
            </table>
          </Panel>
        </>
      );
    }
  };

  const Accounts_render_previous_refund = (account) => {
    let total = 0;
    let filtereditems = [];
    for (let i = 0; i < journals.length; i++) {
      if (
        journals[i].account == account.id &&
        journals[i].invoice > 0 &&
        journals[i].Group == "Assets" &&
        journals[i].details.includes("Sell with invoice no:") &&
        moment(journals[i].invoiceDate).format("DD-MM-YYYY") !=
        moment(journals[i].created).format("DD-MM-YYYY") &&
        !moment(journals[i].invoiceDate, "YYYY-MM-DDTHH:mm:ss").isBetween(
          moment(start.current),
          moment(end.current)
        )
      ) {
        filtereditems.push(journals[i]);
        if (journals[i].outlet == location.current) {
          if (!journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            total -= parseFloat(journals[i].amount);
          }
        } else if (location.current == "") {
          if (!journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            total -= parseFloat(journals[i].amount);
          }
        }
      }
    }
    // totalcash.current += parseFloat(total);
    if (total > 0) {
      return (
        <>
          <Panel
            header={<b>{account.name}</b>}
            key={account.id}
            extra={<b>{formatter.format(parseFloat(total).toFixed(2))}</b>}
            showArrow={false}
            style={{ border: "none" }}
          >
            <table className="history_table" style={{ width: "100%" }}>
              <tr>
                <td>Group</td>
                <td>Sub Group</td>
                <td>Particulars</td>
                <td>Type</td>
                <td>Amount</td>
              </tr>
              {filtereditems.map((journal) => {
                if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  journal.outlet == location.current
                ) {
                  console.log(journal);
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                } else if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  location.current == ""
                ) {
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                }
              })}
            </table>
          </Panel>
        </>
      );
    }
  };

  const Accounts_render_due = (account) => {
    let total = 0;
    let filtereditems = [];
    for (let i = 0; i < journals.length; i++) {
      if (
        journals[i].account == account.id &&
        journals[i].invoice > 0 &&
        journals[i].Group == "Assets" &&
        journals[i].details.includes("Sell with invoice no:") &&
        moment(journals[i].invoiceDate).format("DD-MM-YYYY") !=
        moment(journals[i].created).format("DD-MM-YYYY") &&
        moment(journals[i].invoiceDate, "YYYY-MM-DDTHH:mm:ss").isBetween(
          moment(start.current),
          moment(end.current)
        )
      ) {
        // console.log(journals[i]);
        if (journals[i].increase) {
          filtereditems.push(journals[i]);
        }
        if (journals[i].outlet == location.current) {
          if (journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            // total -= parseFloat(journals[i].amount);
          }
        } else if (location.current == "") {
          if (journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            // total -= parseFloat(journals[i].amount);
          }
        }
      }
    }
    current_due_collection.current += parseFloat(total);
    if (total > 0) {
      return (
        <>
          <Panel
            header={<b>{account.name}</b>}
            key={account.id}
            extra={<b>{formatter.format(parseFloat(total).toFixed(2))}</b>}
            showArrow={false}
            style={{ border: "none" }}
          >
            <table className="history_table" style={{ width: "100%" }}>
              <tr>
                <td>Group</td>
                <td>Sub Group</td>
                <td>Particulars</td>
                <td>Type</td>
                <td>Amount</td>
              </tr>
              {filtereditems.map((journal) => {
                if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  journal.outlet == location.current
                ) {
                  console.log(journal);
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                } else if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  location.current == ""
                ) {
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                }
              })}
            </table>
          </Panel>
        </>
      );
    }
  };
  const Accounts_render_refund = (account) => {
    let total = 0;
    let filtereditems = [];
    for (let i = 0; i < journals.length; i++) {
      if (
        journals[i].account == account.id &&
        journals[i].invoice > 0 &&
        journals[i].Group == "Assets" &&
        journals[i].details.includes("Sell with invoice no:") &&
        moment(journals[i].invoiceDate).format("DD-MM-YYYY") !=
        moment(journals[i].created).format("DD-MM-YYYY") &&
        moment(journals[i].invoiceDate, "YYYY-MM-DDTHH:mm:ss").isBetween(
          moment(start.current),
          moment(end.current)
        )
      ) {
        // console.log(journals[i]);
        filtereditems.push(journals[i]);
        if (journals[i].outlet == location.current) {
          if (!journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            total -= parseFloat(journals[i].amount);
          }
        } else if (location.current == "") {
          if (!journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            total -= parseFloat(journals[i].amount);
          }
        }
      }
    }
    current_due_collection.current += parseFloat(total);
    if (total > 0) {
      return (
        <>
          <Panel
            header={<b>{account.name}</b>}
            key={account.id}
            extra={<b>{formatter.format(parseFloat(total).toFixed(2))}</b>}
            showArrow={false}
            style={{ border: "none" }}
          >
            <table className="history_table" style={{ width: "100%" }}>
              <tr>
                <td>Group</td>
                <td>Sub Group</td>
                <td>Particulars</td>
                <td>Type</td>
                <td>Amount</td>
              </tr>
              {filtereditems.map((journal) => {
                if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  journal.outlet == location.current
                ) {
                  console.log(journal);
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                } else if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  location.current == ""
                ) {
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                }
              })}
            </table>
          </Panel>
        </>
      );
    }
  };

  const Accounts_render_current = (account) => {
    let total = 0;
    let coount = 0;
    let filtereditems = [];
    // console.log("------------------------");
    for (let i = 0; i < journals.length; i++) {
      if (
        journals[i].account == account.id &&
        journals[i].invoice > 0 &&
        journals[i].Group == "Assets" &&
        journals[i].details.includes("Sell with invoice no:") &&
        moment(journals[i].invoiceDate).format("DD-MM-YYYY") ==
        moment(journals[i].created).format("DD-MM-YYYY")
      ) {
        // console.log(journals[i]);
        if (journals[i].increase) {
          coount++;
          filtereditems.push(journals[i]);
        }

        if (journals[i].outlet == location.current) {
          if (journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            // total -= parseFloat(journals[i].amount);
          }
        } else if (location.current == "") {
          if (journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            // total -= parseFloat(journals[i].amount);
          }
        }
      }
    }
    totalcash.current += parseFloat(total);

    if (total > 0) {
      return (
        <>
          <Panel
            header={<b>{account.name}</b>}
            key={account.id}
            extra={<b>{formatter.format(parseFloat(total).toFixed(2))}</b>}
            showArrow={false}
            style={{ border: "none" }}
          >
            <table className="history_table" style={{ width: "100%" }}>
              <tr>
                <td>Group</td>
                <td>Sub Group</td>
                <td>Particulars</td>
                <td>Type</td>
                <td>Amount</td>
              </tr>
              {filtereditems.map((journal) => {
                if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  journal.outlet == location.current
                ) {
                  console.log(journal);
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                } else if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  location.current == ""
                ) {
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                }
              })}
            </table>
          </Panel>
        </>
      );
    }
  };

  const Accounts_render_current_refund = (account) => {
    let total = 0;
    let coount = 0;
    let filtereditems = [];
    // console.log("------------------------");
    for (let i = 0; i < journals.length; i++) {
      if (
        journals[i].account == account.id &&
        journals[i].invoice > 0 &&
        journals[i].Group == "Assets" &&
        journals[i].details.includes("Sell with invoice no:") &&
        moment(journals[i].invoiceDate).format("DD-MM-YYYY") ==
        moment(journals[i].created).format("DD-MM-YYYY")
      ) {
        // console.log(journals[i]);
        coount++;
        filtereditems.push(journals[i]);
        if (journals[i].outlet == location.current) {
          if (!journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            total -= parseFloat(journals[i].amount);
          }
        } else if (location.current == "") {
          if (!journals[i].increase) {
            total += parseFloat(journals[i].amount);
          } else {
            total -= parseFloat(journals[i].amount);
          }
        }
      }
    }
    totalcash.current += parseFloat(total);

    if (total > 0) {
      return (
        <>
          <Panel
            header={<b>{account.name}</b>}
            key={account.id}
            extra={<b>{formatter.format(parseFloat(total).toFixed(2))}</b>}
            showArrow={false}
            style={{ border: "none" }}
          >
            <table className="history_table" style={{ width: "100%" }}>
              <tr>
                <td>Group</td>
                <td>Sub Group</td>
                <td>Particulars</td>
                <td>Type</td>
                <td>Amount</td>
              </tr>
              {filtereditems.map((journal) => {
                if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  journal.outlet == location.current
                ) {
                  console.log(journal);
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                } else if (
                  journal.invoice > 0 &&
                  journal.Group == "Assets" &&
                  journal.details.includes("Sell with invoice no:") &&
                  location.current == ""
                ) {
                  return (
                    <>
                      <tr>
                        <td>{journal.Group}</td>
                        <td>{journal.Subgroup}</td>
                        <td>{journal.details}</td>
                        <td>{journal.type}</td>
                        <td> {journal.amount}</td>
                      </tr>
                    </>
                  );
                }
              })}
            </table>
          </Panel>
        </>
      );
    }
  };

  const Rendercontent = () => {
    if (loading) {
      return (
        <>
          <Skeleton active />
        </>
      );
    } else {
      return (
        <>
          <Row>
            <Col span={24}>
              <h3>Collection</h3>
              <Collapse
                bordered={false}
                collapsible="header"
                style={{ borderRadius: "5px" }}
              >
                {accounts.map((account) => {
                  return Accounts_render_current(account);
                })}
              </Collapse>
              <Divider />
              <h3>Current Due collection</h3>
              <Collapse
                bordered={false}
                collapsible="header"
                style={{ borderRadius: "5px" }}
              >
                {accounts.map((account) => {
                  return Accounts_render_due(account);
                })}
              </Collapse>
              <Divider />
              <h3>Previous Due collection</h3>
              <Collapse
                bordered={false}
                collapsible="header"
                style={{ borderRadius: "5px" }}
              >
                {accounts.map((account) => {
                  return Accounts_render_previous_due(account);
                })}
              </Collapse>
            </Col>

            {/* <Col span={10} offset={2}>
              <h3>Refund</h3>
              <Collapse
                bordered={false}
                collapsible="header"
                style={{ borderRadius: "5px" }}
              >
                {accounts.map((account) => {
                  return Accounts_render_current_refund(account);
                })}
              </Collapse>
              <Divider />
              <h3>Current Refund</h3>
              <Collapse
                bordered={false}
                collapsible="header"
                style={{ borderRadius: "5px" }}
              >
                {accounts.map((account) => {
                  return Accounts_render_refund(account);
                })}
              </Collapse>
              <Divider />
              <h3>Previous Refund</h3>
              <Collapse
                bordered={false}
                collapsible="header"
                style={{ borderRadius: "5px" }}
              >
                {accounts.map((account) => {
                  return Accounts_render_previous_refund(account);
                })}
              </Collapse>
            </Col> */}

            <Divider />

            <Col span={24}>
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <b>Invoices :</b>{" "}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {formatter.format(total_data.current["total_invoices"])}
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Bill :</b>{" "}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {formatter.format(total_data.current["total_sales"])}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Discount :</b>{" "}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {formatter.format(total_data.current["total_discount"])}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Payment (Collection + Current due collection) :</b>{" "}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {formatter.format(
                      parseFloat(
                        totalcash.current + current_due_collection.current
                      ).toFixed(2)
                    )}
                  </td>
                </tr>

                {/* <tr>
                  <td>
                    <b>Due :</b>{" "}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {formatter.format(
                      total_data.current["total_sales"] - totalcash.current
                    )}
                  </td>
                </tr> */}
              </table>
            </Col>
          </Row>
        </>
      );
    }
  };

  return (
    <>
      <div>{Rendercontent()}</div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  getAllAccount,
  getJournalSearchResult,
  getJournalSearchResultwithinvoice,
})(CashRegister);
