import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import ReactToPrint from "react-to-print";
import { getExcelSalesReport } from "../../../actions/report";
import { getAllOutlet } from "../../../actions/warehouseAction";
import moment from "moment";

// import RenderTable from "./RenderTable";
// import Excelldownload from "./Excelldownload";
import dateFormat from "dateformat";
import invoiceDetails from "./invoiceDetails";
import Excelldownload from "./Excelldownload";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Divider,
  Skeleton,
  Card,
  Button,
} from "antd";
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const SalesReport = ({
  getExcelSalesReport,
  businessprofile,
  Auth,
  getAllOutlet,
}) => {
  const exceldata = useRef([]);
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [accounts, setaccounts] = useState([]);
  const [outletlist, setoutletlist] = useState([]);
  const [loading, setloading] = useState(true);
  const [reload, setreload] = useState(false);
  const [type, setType] = useState("date");
  const from = useRef("2021-01-01");
  const to = useRef("2071-01-01");
  const start = useRef("");
  const end = useRef("");
  const disc = useRef(0);
  const vat = useRef(0);
  const bill = useRef(0);
  const due = useRef(0);
  const location = useRef(Auth.profile.branch.id);
  const [report, setReport] = useState([]);
  var formatter = new Intl.NumberFormat("en-IN");

  useEffect(() => {
    exceldata.current = [];
    setloading(true);
    let promises = [];
    getAllOutlet().then((result) => {
      setoutletlist(result);
    });

    promises.push(
      getExcelSalesReport(start.current, end.current, location.current).then(
        function (result) {
          setData(result);
          let cnt = 1;
          disc.current = 0;
          vat.current = 0;
          bill.current = 0;
          due.current = 0;
          result.map((item) => {
            let invoice_number = item.invoice_number;
            let issue_date = item.issue_date;
            let name = item.contact ? item.Contact[0].name : "";
            let phone = item.contact ? item.Contact[0].phone : "";
            let bill = item.bill;
            let discount = item.discount;
            let tax = item.tax;
            let payment = item.payment;
            let due = 0;
            let total = item.bill;
            let invoice_items = item.invoice_items;

            invoice_items.map((invoice_item) => {
              let Details = invoice_item.Details;
              let quantity = invoice_item.quantity;
              let price = invoice_item.price;
              let report_item = {
                sl: parseInt(cnt),
                invoice_number: invoice_number,
                issue_date: issue_date,
                name: name,
                phone: phone,
                bill: bill,
                discount: discount,
                tax: tax,
                payment: payment,
                due: due,
                total: total,
                Details: Details,
                quantity: quantity,
                price: price,
              };
              setReport((report) => [...report, report_item]);
            });
            cnt = parseInt(cnt) + 1;
          });
        }
      )
    );
    Promise.all(promises).then(function (values) {
      console.log(report);
      setloading(false);
    });
  }, [reload]);

  const renderremaingitem = (items, methods, length) => {
    return items.map((item, index) => {
      if (index != 0) {
        return (
          <tr>
            <td>
              {item ? (item?.Product ? item?.Product[0].barcode : "-") : "-"}
            </td>
            <td>
              {item ? (
                item?.Product ? (
                  item?.Product[0].title
                ) : (
                  <>
                    <div
                      className="d-div"
                      dangerouslySetInnerHTML={{
                        __html: item.details,
                      }}
                    ></div>
                    <div
                      className="d-div"
                      dangerouslySetInnerHTML={{
                        __html: item.Details,
                      }}
                    ></div>
                  </>
                )
              ) : (
                "-"
              )}
            </td>
            <td>
              {item
                ? item?.Product
                  ? item?.Product[0]?.parent_category
                  : "-"
                : "-"}
            </td>
            <td>
              {item ? (item?.Product ? item?.Product[0]?.category : "-") : "-"}
            </td>
            <td>
              {item ? (item?.Product ? item?.Product[0]?.color : "-") : "-"}
            </td>
            <td>
              {item ? (item?.Product ? item?.Product[0]?.size : "-") : "-"}
            </td>
            <td>{item ? item?.quantity : "-"}</td>
            <td>{item ? item?.price : "-"}</td>
            <td>{item ? item?.method : "-"}</td>
            <td>{item ? item?.amount : "-"}</td>
          </tr>
        );
      }
    });
  };

  const SwitchablePicker = () => {
    return (
      <Row>
        <Space>
          Select a date range :
          <RangePicker
            picker={type}
            onChange={(value) => {
              if (value) {
                from.current = value[0].format("YYYY-MM-DD");
                to.current = value[1].format("YYYY-MM-DD");
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                setreload(!reload);
              }
            }}
          />
          {/* <Select value={type} onChange={setType}>
            <Option value="date">Date</Option>

            <Option value="month">Month</Option>

            <Option value="year">Year</Option>
          </Select> */}
          {Auth.permissions.includes("Report.Excel Sales Report_is_location") ||
          Auth.superuser ? (
            <>
              {" "}
              Outlet :
              <Select
                placeholder="Please select a outlet"
                style={{ width: 200 }}
                onChange={(e) => {
                  console.log(e);
                  location.current = e;
                  setreload(!reload);
                }}
              >
                <Option value="">All</Option>
                {outletlist.map((outlet) => {
                  return <Option value={outlet.id}>{outlet.name}</Option>;
                })}
              </Select>
            </>
          ) : (
            ""
          )}
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => componentRef.current}
          />
          <Excelldownload data={exceldata.current} data1={data} />
        </Space>
      </Row>
    );
  };

  const Rendercontent = () => {
    if (loading) {
      return <>Loading .....</>;
    } else {
      return (
        <>
          <div
            // className="invoice_print_fontSize"
            // style={{ fontSize: "12px" }}
            ref={componentRef}
            style={{ padding: "10px" }}
          >
            <Row
              style={{
                borderBottom: "2px solid lightgray",
                paddingBottom: "5px",
                marginBottom: "20px",
              }}
            >
              <Col span={17} style={{ paddingTop: "10px" }}>
                <div
                  className="d-div"
                  dangerouslySetInnerHTML={{
                    __html: businessprofile.address,
                  }}
                ></div>
              </Col>

              <Col span={7} style={{ textAlign: "right" }}>
                <img
                  src={businessprofile.logo}
                  style={{
                    maxHeight: "60px",
                    right: "0",
                  }}
                />
              </Col>
            </Row>

            <h3 style={{ textAlign: "center" }}>Sales report</h3>

            {/* <RenderTable List={data} /> */}
            <table style={{ width: "100%" }} className="vat_table">
              <tr>
                <th>SL.</th>
                <th>Invoice No.</th>
                <th>Invoice Date</th>
                <th>Customer Name</th>
                <th>Contact No.</th>
                <th>Contact Address</th>
                <th>Product Barcode</th>
                <th>Product Title</th>
                <th>Category</th>
                <th>Sub category</th>
                <th>Color</th>
                <th>Size</th>
                <th>QTY</th>
                <th>Rate</th>
                <th>Sub total</th>
                <th>Disc</th>
                <th>VAT incl.</th>
                <th>Bill</th>
                <th>Paid</th>
                <th>Due</th>
                <th>Method</th>
                <th>Amount</th>
              </tr>

              {data.map((item, index) => {
                console.log("-----------------");
                console.log(item);
                item?.printarray?.map((exdata, exindex) => {
                  const obj = {
                    invoice_number: exindex == 0 ? item.invoice_number : "",
                    issue_date: exindex == 0 ? item.issue_date : "",
                    contact_name:
                      exindex == 0
                        ? item.contact
                          ? item.Contact[0].name
                          : ""
                        : "",
                    contact_phone:
                      exindex == 0
                        ? item.contact
                          ? item.Contact[0].phone
                          : ""
                        : "",
                    contact_address:
                      exindex == 0
                        ? item.contact
                          ? item.Contact[0].address
                          : ""
                        : "",
                    barcode: exdata
                      ? exdata?.Product
                        ? exdata?.Product[0].barcode
                        : "-"
                      : "-",
                    title: exdata
                      ? exdata?.Product
                        ? exdata?.Product[0].title
                        : (typeof exdata?.details != "undefined"
                            ? exdata?.details
                            : "") +
                          "" +
                          (typeof exdata?.Details != "undefined"
                            ? exdata?.Details
                            : "")
                      : "-",
                    parent_category: exdata
                      ? exdata?.Product
                        ? exdata?.Product[0]?.parent_category
                        : "-"
                      : "-",
                    category: exdata
                      ? exdata?.Product
                        ? exdata?.Product[0]?.category
                        : "-"
                      : "-",
                    Color: exdata
                      ? exdata?.Product
                        ? exdata?.Product[0]?.color
                        : "-"
                      : "-",
                    Size: exdata
                      ? exdata?.Product
                        ? exdata?.Product[0]?.size
                        : "-"
                      : "-",
                    item_quantity: exdata ? parseFloat(exdata?.quantity) : 0,
                    price: exdata ? parseFloat(exdata?.price) : 0,

                    sub_total: exindex == 0 ? parseFloat(item.total_price) : 0,
                    discount: exindex == 0 ? parseFloat(item.discount) : 0,
                    vat: exindex == 0 ? parseFloat(item.tax) : 0,
                    bill: exindex == 0 ? parseFloat(item.bill) : 0,
                    payment: exindex == 0 ? parseFloat(item.payment) : 0,
                    due: exindex == 0 ? parseFloat(item.due) : 0,

                    method: exdata ? exdata?.method : "-",
                    amount: exdata ? parseFloat(exdata?.amount) : 0,
                  };
                  console.log(obj);
                  exceldata.current.push(obj);
                });

                disc.current += parseFloat(item.discount);
                vat.current += parseFloat(item.tax);
                bill.current += parseFloat(item.bill);
                due.current += parseFloat(item.due);
                let length = 0;
                if (item.invoice_items.length > item.payment_methods.length) {
                  length = item.invoice_items.length;
                } else {
                  length = item.payment_methods.length;
                }
                if (true) {
                  return (
                    <>
                      <tr>
                        <td rowspan={length}>{index + 1}</td>
                        <td rowspan={length}>{item.invoice_number}</td>
                        <td rowspan={length}>{item.issue_date}</td>
                        <td rowspan={length}>
                          {item.contact ? item.Contact[0].name : ""}
                        </td>
                        <td rowspan={length}>
                          {item.contact ? item.Contact[0].phone : ""}
                        </td>
                        <td rowspan={length}>
                          {item.contact ? item.Contact[0].address : ""}
                        </td>
                        <td>
                          {item.invoice_items[0]
                            ? item.invoice_items[0]?.Product
                              ? item.invoice_items[0]?.Product[0].barcode
                              : "-"
                            : "-"}
                        </td>

                        <td>
                          {item.invoice_items[0] ? (
                            item.invoice_items[0]?.Product ? (
                              item.invoice_items[0]?.Product[0].title
                            ) : (
                              <>
                                <div
                                  className="d-div"
                                  dangerouslySetInnerHTML={{
                                    __html: item.invoice_items[0]?.details,
                                  }}
                                ></div>
                                <div
                                  className="d-div"
                                  dangerouslySetInnerHTML={{
                                    __html: item.invoice_items[0]?.Details,
                                  }}
                                ></div>
                              </>
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {item.invoice_items[0]
                            ? item.invoice_items[0]?.Product
                              ? item.invoice_items[0]?.Product[0]
                                  ?.parent_category
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {item.invoice_items[0]
                            ? item.invoice_items[0]?.Product
                              ? item.invoice_items[0]?.Product[0]?.category
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {item.invoice_items[0]
                            ? item.invoice_items[0]?.Product
                              ? item.invoice_items[0]?.Product[0]?.color
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {item.invoice_items[0]
                            ? item.invoice_items[0]?.Product
                              ? item.invoice_items[0]?.Product[0]?.size
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {item.invoice_items[0]
                            ? item.invoice_items[0]?.quantity
                            : "-"}
                        </td>
                        <td>
                          {item.invoice_items[0]
                            ? item.invoice_items[0]?.price
                            : "-"}
                        </td>
                        <td rowspan={length}>
                          {/* {item.total_price} */}
                          {formatter.format(item.total_price)}
                        </td>
                        <td rowspan={length}>
                          {formatter.format(item.discount)}
                        </td>
                        <td rowspan={length}>{formatter.format(item.tax)}</td>
                        <td rowspan={length}>{formatter.format(item.bill)}</td>
                        <td rowspan={length}>
                          {formatter.format(item.payment)}
                        </td>
                        {/* <td>{item.due}</td> */}
                        <td rowspan={length}>{item.due}</td>
                        <td>
                          {item.payment_methods[0]
                            ? item.payment_methods[0]?.method
                            : "-"}
                        </td>
                        <td>
                          {item.payment_methods[0]
                            ? item.payment_methods[0]?.amount
                            : "-"}
                        </td>
                      </tr>
                      {item.printarray.length > 1 ? (
                        renderremaingitem(item.printarray)
                      ) : (
                        <></>
                      )}
                    </>
                  );
                }
              })}
              <tr>
                <th colSpan={14}>Total</th>

                <th style={{ border: "2px solid gray" }}>
                  {formatter.format(bill.current)}
                </th>
                <th style={{ border: "2px solid gray" }}>
                  {formatter.format(disc.current)}
                </th>
                <th style={{ border: "2px solid gray" }}>
                  {formatter.format(vat.current)}
                </th>
                <th style={{ border: "2px solid gray" }}>
                  {formatter.format(bill.current)}
                </th>
                <th style={{ border: "2px solid gray" }}>
                  {formatter.format(bill.current - due.current)}
                </th>
                <th style={{ border: "2px solid gray" }}>
                  {formatter.format(due.current)}
                </th>
              </tr>
            </table>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Report</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              <Row>
                <Col
                  span={24}
                  style={{ padding: "1rem", border: "1px solid whitesmoke" }}
                >
                  {SwitchablePicker()}
                  <Divider />
                  {Rendercontent()}
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  getExcelSalesReport,
  getAllOutlet,
})(SalesReport);
