import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSpecificUserEmployeeLeave,
  getAllEmployeeLeave,
} from "../../../actions/employeeLeaveActions";
import {
  getSpecificEmployeeAttendence,
  getAllEmployeeAttendenceOfMonth,
  getAllEmployeeAttendenceOfDateRange,
  EmployeeAttendenceReportbyDateRange,
} from "../../../actions/AttendenceAction";
import { getAllEmployee } from "../../../actions/employeeAction";
import Rendertable from "./Rendertable";
import moment, { invalid } from "moment";
import dateFormat from "dateformat";
import { Row, Col, Skeleton, Collapse } from "antd";
const { Panel } = Collapse;
var formatter = new Intl.NumberFormat("en-IN");

const EmployeeAttendanceList = ({
  getAllEmployee,
  employeeAttendancedetails,
  getAllEmployeeAttendenceOfMonth,
  getAllEmployeeAttendenceOfDateRange,
  EmployeeAttendenceReportbyDateRange,
  getAllEmployeeLeave,
  employeeLeave,
  employeeDetails,
  employee,
  startDate,
  endDate,
  data,
  setData,
  updatelist,
  setUpdatelist,
  componentRef,
  businessprofile,
  reload,
  setreload,
  selectedLocation,
}) => {
  const [loading, setloading] = useState(true);
  const [datas, setdatas] = useState([]);
  const [leavecount, setleavecount] = useState(false);
  const [presentcount, setpresentcount] = useState(false);
  const [combineData, setCombineData] = useState([]);
  const [daysInMonth, setDaysInMonth] = useState(30);

  let start = new Date(startDate.current);
  let end = new Date(endDate.current);

  const onChange = (key) => {
    // console.log(key);
  };
  // var days = function (startDate, endDate) {
  //   //console.log(startDate);
  //   //console.log(endDate);
  //   var a = moment(startDate);
  //   var b = moment(endDate);
  //   let day = b.diff(a, "days") + 1; // =1
  //   //console.log(day);
  //   return day;
  // };
  // //const dateFormat = "YYYY-MM-DD";

  // const format24 = "HH:mm";

  useEffect(() => {
    setloading(true);
    let promises = [];
    getAllEmployee();
    promises.push(
      EmployeeAttendenceReportbyDateRange(
        startDate.current,
        endDate.current,
        employee
      ).then((e) => {
        setdatas(e);
        setloading(false);
      })
    );
    promises.push(getAllEmployeeLeave());
    promises.push(getAllEmployee());
    Promise.all(promises).then((values) => { });

    // setDaysInMonth(days(startDate, endDate));

    setUpdatelist(true);
  }, [reload]);

  const attendacnecount = (employee) => {
    // console.log(employee);
    // console.log(selectedLocation);
    let present = 0;
    let absent = 0;
    let late = 0;
    let overtime = 0;
    let acceptedLeave = 0;
    let pendingLeave = 0;
    let rejectedLeave = 0;
    if (!selectedLocation > 0) {
      for (let i = 0; i < datas.length; i++) {
        if (datas[i].employee == employee.id) {
          // console.log(datas[i]);
          if (datas[i].isAttended) {
            present += 1;
            if (datas[i].isLate) {
              late += 1;
            }
            overtime += (datas[i].overTime - datas[i].lateTime);
          } else {
            absent += 1;
            overtime += (datas[i].overTime - datas[i].lateTime);
          }
        }
      }
      for (let j = 0; j < employeeLeave.length; j++) {
        if (
          employee.id == employeeLeave[j].employeeId &&
          employeeLeave[j].leaveStatus == "approved"
        ) {
          acceptedLeave += employeeLeave[j].leaveDays;
        } else if (
          employee.id == employeeLeave[j].employeeId &&
          employeeLeave[j].leaveStatus == "pending"
        ) {
          pendingLeave += employeeLeave[j].leaveDays;
        } else if (employee.id == employeeLeave[j].employeeId) {
          rejectedLeave += employeeLeave[j].leaveDays;
        }
      }

      return (
        <>
          <tr>
            <td style={{ textAlign: "left" }}>{employee.id}</td>
            <td style={{ textAlign: "left" }}>{employee.name}</td>
            <td style={{ textAlign: "left" }}>{employee.user_roleName}</td>
            <td style={{ textAlign: "left" }}>{employee.branchName}</td>
            <td>{present > 0 ? present : "-"}</td>
            <td>{absent > 0 ? absent : "-"}</td>
            <td>{late > 0 ? late : "-"}</td>
            <td>{overtime > 0 ? parseFloat(overtime).toFixed(2) : "-"}</td>
            <td>{acceptedLeave > 0 ? acceptedLeave : "-"}</td>
          </tr>
        </>
      );
    } else if (employee.branch.id == selectedLocation) {
      for (let i = 0; i < datas.length; i++) {
        if (datas[i].employee == employee.id) {
          // console.log(datas[i]);
          if (datas[i].isAttended) {
            present += 1;
            if (datas[i].isLate) {
              late += 1;
            }
            overtime += (datas[i].overTime - datas[i].lateTime);
          } else {
            absent += 1;
            overtime += (datas[i].overTime - datas[i].lateTime);
          }
        }
      }
      for (let j = 0; j < employeeLeave.length; j++) {
        if (
          employee.id == employeeLeave[j].employeeId &&
          employeeLeave[j].leaveStatus == "approved"
        ) {
          acceptedLeave += employeeLeave[j].leaveDays;
        } else if (
          employee.id == employeeLeave[j].employeeId &&
          employeeLeave[j].leaveStatus == "pending"
        ) {
          pendingLeave += employeeLeave[j].leaveDays;
        } else {
          rejectedLeave += employeeLeave[j].leaveDays;
        }
      }

      return (
        <>
          <tr>
            <td style={{ textAlign: "left" }}>{employee.id}</td>
            <td style={{ textAlign: "left" }}>{employee.name}</td>
            <td style={{ textAlign: "left" }}>{employee.user_roleName}</td>
            <td style={{ textAlign: "left" }}>{employee.branchName}</td>
            <td>{present > 0 ? present : "-"}</td>
            <td>{absent > 0 ? absent : "-"}</td>
            <td>{late > 0 ? late : "-"}</td>
            <td>{overtime > 0 ? parseFloat(overtime).toFixed(2) : "-"}</td>
            <td>{acceptedLeave > 0 ? acceptedLeave : "-"}</td>
          </tr>
        </>
      );
    }
  };

  if (loading) {
    return <Skeleton active />;
  } else {
    return (
      <>
        <h2 style={{ textAlign: "center" }}>Attendance Report</h2>
        <p style={{ textAlign: "center" }}>
          From : <b>{startDate.current.split("T")[0]}</b> to :{" "}
          <b>{endDate.current.split("T")[0]}</b>
        </p>
        <table className="attendacne_table" style={{ width: "100%" }}>
          <tr style={{ background: "#FAFAFA" }}>
            <th style={{ textAlign: "left" }}>ID</th>
            <th style={{ textAlign: "left" }}>Employee Name</th>
            <th style={{ textAlign: "left" }}>Department</th>
            <th style={{ textAlign: "left" }}>Branch</th>
            <th>Present / day</th>
            <th>Absent / day</th>
            <th>Late / day</th>
            <th>Overtime / hr</th>
            <th>Leave</th>
          </tr>
          {employee == ""
            ? employeeDetails.map((item) => {
              return attendacnecount(item);
            })
            : employeeDetails.map((item) => {
              if (item.id == employee) return attendacnecount(item);
            })}
        </table>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    employeeAttendancedetails:
      state.employeeAttendance.employeeAttendancelistMonth,
    employeeLeave: state.employeeLeave.employeeLeavelist,
    employeeDetails: state.employee.employeelist,
    businessprofile: state.settings.businessprofile,
  };
};

export default connect(mapStateToProps, {
  getSpecificUserEmployeeLeave,
  getSpecificEmployeeAttendence,
  getAllEmployeeAttendenceOfMonth,
  getAllEmployeeAttendenceOfDateRange,
  getAllEmployeeLeave,
  getAllEmployee,
  EmployeeAttendenceReportbyDateRange,
})(EmployeeAttendanceList);
