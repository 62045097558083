import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excelldownload = ({ data }) => {
  // console.log(data);
  return (
    <ExcelFile
      style={{ width: "100%" }}
      element={
        <Button type="primary" style={{ align: "right", width: "100%" }}>
          Download
        </Button>
      }
    >
      <ExcelSheet data={data} name="All Invoices">
        {/* <ExcelColumn label="SL" value="index" /> */}
        <ExcelColumn label="Invoice No." value="invoice_number" />
        <ExcelColumn label="Order No." value="order_number" />
        <ExcelColumn
          label="Contact"
          value={(details) => {
            if (details.contact) return details.Contact[0].name;
          }}
        />
        <ExcelColumn label="Status" value="status" />
        <ExcelColumn label="Location" value="Location_name" />
        <ExcelColumn label="Issued Date" value="issue_date" />
        <ExcelColumn label="Delivery Date" value="delivery_date" />
        <ExcelColumn label="Delivery Status" value="delivery_status" />
        <ExcelColumn
          label="Remarks"
          value={(details) => {
            return details.remarks;
          }}
        />
        {/* <ExcelColumn
          label="Debit"
          value={(details) => 
         <>{details.amount}</>
        }
        />
        <ExcelColumn
          label="Credit"
          value={(details) => 
            <>{details.amount}</>
        }
        /> */}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Excelldownload;
