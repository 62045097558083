import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import Rendertable from "./Rendertable";
import { getProductSearchResultforsales } from "../../../actions/productDetails";
import { getAllLocation } from "../../../actions/warehouseAction";
import { getAllAttribute } from "../../../actions/attributeAction";
import { getAllCategory } from "../../../actions/categoryAction";

import {
  Divider,
  AutoComplete,
  Row,
  Col,
  Select,
  Skeleton,
  TreeSelect,
  InputNumber,
} from "antd";
const { Option } = Select;

const SearchStock = ({
  getProductSearchResultforsales,
  getAllAttribute,
  getAllLocation,
  categoryList,
  getAllCategory,
  Auth,
  attributelist,
}) => {
  const [List, setList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [reload, setreload] = useState(false);
  const [loading, setloading] = useState(true);
  const location = useRef(Auth.profile.branch.id);
  const keyward = useRef("");
  const pageno = useRef(1);
  const page_size = useRef(10);
  const searchColor = useRef("");
  const lowrange = useRef("");
  const heighrange = useRef("");
  let total_data = useRef({});
  const searchCategory = useRef("");

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
      setreload(!reload);
    }
  };

  useEffect(() => {
    getAllCategory();
    getAllAttribute();
    getAllLocation().then((result) => {
      setLocations(result);
    });
    getProductSearchResultforsales(
      keyward.current,
      location.current,
      pageno.current,
      page_size.current,
      searchCategory.current,
      searchColor.current,
      lowrange.current,
      heighrange.current
    ).then((result) => {
      total_data.current = {
        total_purchase_price: result.total_purchase_price,
        total_selling_price: result.total_selling_price,
        total_quantity: result.total_quantity,
        current_purchase_price: result.current_purchase_price,
        current_selling_price: result.current_selling_price,
        current_quantity: result.current_quantity,
      };
      setList(result);
      setloading(false);
    });
    // setreload(false);
  }, [reload, pageno.current, page_size.current]);

  const renderdata = () => {
    if (loading) {
      return <Skeleton active />;
    } else {
      return (
        <Rendertable
          List={List}
          pageno={pageno}
          page_size={page_size}
          setreload={setreload}
          setloading={setloading}
          total_data={total_data}
        />
      );
    }
  };

  return (
    <>
      <Row>
        <Col span={5}>
          <h3>Enter barcode or product code</h3>
          <AutoComplete
            placeholder="input search text"
            // onChange={onChange}
            onKeyUp={onChange}
            style={{ width: "100%" }}
          />
        </Col>
        {Auth.permissions.includes("Stock.Current Stock_is_location") ||
          Auth.superuser ? (
          <Col span={4}>
            <h3>Location</h3>
            <Select
              placeholder="Please select a outlet / warehouse"
              defaultValue={Auth.profile.branch.id}
              style={{ width: "100%" }}
              onChange={(e) => {
                location.current = e;
                pageno.current = 1;
                setloading(true);
                setreload(!reload);
              }}
            >
              <Option value="">All</Option>
              {locations.map((outlet) => {
                return <Option value={outlet.id}>{outlet.name}</Option>;
              })}
            </Select>
          </Col>
        ) : (
          ""
        )}
        <Col span={4}>
          <h3>Category</h3>

          <TreeSelect
            style={{ width: "100%" }}
            treeData={categoryList}
            onChange={(value) => {
              pageno.current = 1;
              searchCategory.current = value;
              setloading(true);
              setreload(!reload);
            }}
          />
        </Col>

        <Col span={4}>
          <h3>Color</h3>
          <Select
            showSearch
            placeholder="Please pick a color"
            style={{ width: "100%" }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              pageno.current = 1;
              searchColor.current = value;
              setloading(true);
              setreload(!reload);
            }}
          >

            {attributelist[1].terms.map((color) => {
              return <Option value={color.id}>{color.name}</Option>;
            })}
          </Select>
        </Col>
        <Col span={3}>
          <h3>Lowest price</h3>
          <InputNumber
            onKeyUp={(event) => {
              if (event.keyCode == 13) {
                pageno.current = 1;
                lowrange.current = event.target.value;
                setloading(true);
                setreload(!reload);
              }
            }}
          />
        </Col>
        <Col span={3}>
          <h3>Highest price</h3>
          <InputNumber
            onKeyUp={(event) => {
              if (event.keyCode == 13) {
                pageno.current = 1;
                heighrange.current = event.target.value;
                setloading(true);
                setreload(!reload);
              }
            }}
          />
        </Col>
      </Row>

      <Divider />
      {renderdata()}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    // List: state.ProductDetails.productdetails,
    categoryList: state.category.categorylist,
    Auth: state.auth,
    attributelist: state.attribute.attributelist,
  };
};

export default connect(mapStateToProps, {
  getProductSearchResultforsales,
  getAllAttribute,
  getAllLocation,
  getAllCategory,
})(SearchStock);
