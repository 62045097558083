import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar";
import Navbar from "../layout/Navbar";
import { getAllWordrobe, getAllWordrobeP } from "../../actions/wordrobe";

import RenderTable from "./RenderTable";

import {
  Layout, Breadcrumb, Row, Col, AutoComplete,
  Divider, Button,
  DatePicker,
} from "antd";
const { Content } = Layout;
const { RangePicker } = DatePicker;

const ProductDetails = ({ getAllWordrobe, getAllWordrobeP }) => {
  const [details, setdetails] = useState([]);
  const pageno = useRef(1);
  const page_size = useRef(10);
  const start = useRef("");
  const end = useRef("");
  const keyward = useRef("");
  const [reload, setReload] = useState(true);
  useEffect(() => {
    getAllWordrobeP(keyward.current, start.current, end.current, pageno.current, page_size.current).then(function (result) {
      console.log(result);
      setdetails(result);
    });
  }, [reload]);

  const SwitchablePicker = () => {
    return (
      <Row wrap={false}>
        <Col flex="auto">
          keyword :
          <AutoComplete
            placeholder="input search text"
            // onChange={onChange}
            onKeyUp={(event) => {
              keyward.current = event.target.value;
            }}
            style={{ width: "100%", minWidth: "140px" }}
          />
        </Col>
        <Col flex="auto">
          Issue date :<br></br>
          <RangePicker
            style={{ width: "100%" }}
            picker="date"
            onChange={(value) => {
              if (value) {
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
              }
            }}
          />
        </Col>


        <Col flex="auto">
          .
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={() => {
              pageno.current = 1;
              setReload(!reload);
            }} >Search</Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Wordrobe</Breadcrumb.Item>
              <Breadcrumb.Item>All</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              {SwitchablePicker()}
              <Divider />
              <RenderTable
                details={details}
                pageno={pageno}
                page_size={page_size}
                reload={reload}
                setReload={setReload} />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default connect(null, { getAllWordrobe, getAllWordrobeP })(ProductDetails);
