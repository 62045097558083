import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import ReactToPrint from "react-to-print";
import {
  getSalesReportByInvoiceCount,
  getSalesReport,
  getSalesPersonReport,
} from "../../../actions/report";
import { getAllOutlet } from "../../../actions/warehouseAction";
import { getAllAccount } from "../../../actions/accountsAction";
import { getAllEmployee } from "../../../actions/employeeAction";
import moment from "moment";

// import RenderTable from "./RenderTable";
import Excelldownload from "./Excelldownload";
import dateFormat from "dateformat";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Divider,
  Skeleton,
  Card,
  Button,
  AutoComplete,
  Badge,
  Avatar,
  Collapse,
  Spin,
} from "antd";
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;

const SalesReport = ({
  getSalesReportByInvoiceCount,
  getSalesReport,
  getSalesPersonReport,
  getAllOutlet,
  getAllAccount,
  businessprofile,
  Auth,
  employeeList,
  getAllEmployee,
}) => {
  var formatter = new Intl.NumberFormat("en-IN");
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [accounts, setaccounts] = useState([]);
  const [outletlist, setoutletlist] = useState([]);
  const [loading, setloading] = useState(true);
  const [reload, setreload] = useState(false);
  const [spinning, setspinning] = useState(false);
  const [type, setType] = useState("date");
  const from = useRef("2021-01-01");
  const to = useRef("2071-01-01");
  const start = useRef("");
  const end = useRef("");
  const keyward = useRef("");
  const status = useRef("");
  const result = useRef([]);
  const location = useRef(
    Auth.profile.branch.is_outlet ? Auth.profile.branch.id : ""
  );
  const pageno = useRef(1);
  const page_size = useRef(10);
  let total_payments = useRef([]);
  let total_data = useRef({});
  useEffect(() => {
    getAllEmployee();
    getAllOutlet().then((result) => {
      setoutletlist(result);
    });

    getAllAccount().then((result) => {
      setaccounts(result);
      result.map((r) => {
        const arr = {
          name: r.name,
          total: 0,
          payment: 0,
        };
        total_payments.current.push(arr);
      });
    });
  }, []);

  useEffect(() => {
    if (start.current != "" && end.current != "") {
      setspinning(true);
      getSalesPersonReport(
        start.current,
        end.current,
        keyward.current,
        "",
        "",
        "",

        status.current,
        "",
        location.current,
        "",
        pageno.current,
        page_size.current
      ).then(function (result) {
        setData(result);
        setloading(false);
        setspinning(false);
      });
    } else {
      if (!loading) {
        alert("Please select a daterange");
      }
      setloading(false);
    }
  }, [reload]);

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
      setreload(!reload);
    }
  };

  const SwitchablePicker = () => {
    return (
      <Row wrap={false}>
        {/* <Col flex="auto">
          keyword :
          <AutoComplete
            placeholder="input search text"
            // onChange={onChange}
            onKeyUp={onChange}
            style={{ width: "100%", minWidth: "140px" }}
          />
        </Col> */}
        <Col flex="auto">
          Issue date :<br></br>
          <RangePicker
            style={{ width: "80%" }}
            picker={type}
            onChange={(value) => {
              if (value) {
                console.log(value);
                from.current = value[0].format("YYYY-MM-DD");
                to.current = value[1].format("YYYY-MM-DD");
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                // setreload(!reload);
              }
            }}
          />
          <Select value={type} onChange={setType} style={{ width: "20%" }}>
            <Option value="date">Date</Option>

            <Option value="month">Month</Option>

            <Option value="year">Year</Option>
          </Select>
        </Col>
        <Col flex="auto">
          Status :
          <Select
            style={{ width: "100%" }}
            placeholder="Status"
            onChange={(value) => {
              status.current = value;
              // setreload(!reload);
            }}
          >
            <Option value="">All</Option>
            <Option value="Pending">Pending</Option>
            <Option value="Received by factory">Received by factory</Option>
            <Option value="Advanced from customer">Advanced from customer</Option>
            <Option value="Ready">Ready</Option>
            <Option value="Picked by courier">Picked by courier</Option>
            <Option value="Delivered">Delivered</Option>
            <Option value="Paid">Paid</Option>
            <Option value="Booked">Booked</Option>
            <Option value="Exchanged">Exchanged</Option>
            <Option value="Cancelled">Cancelled</Option>
          </Select>
        </Col>
        <Col flex="auto">
          Outlet:
          <Select
            placeholder="Please select a outlet"
            style={{ width: "100%" }}
            defaultValue={
              Auth.profile.branch.is_outlet ? Auth.profile.branch.id : ""
            }
            disabled={
              Auth.permissions.includes("Report.Sales report_is_location") ||
                Auth.superuser
                ? false
                : true
            }
            onChange={(e) => {
              console.log(e);
              location.current = e;
              // setreload(!reload);
            }}
          >
            <Option value="">All</Option>
            {outletlist.map((outlet) => {
              return <Option value={outlet.id}>{outlet.name}</Option>;
            })}
          </Select>
        </Col>
        <Col flex="auto">
          .
          <Button
            type="primary"
            style={{ width: "100%", background: "green" }}
            onClick={() => {
              setreload(!reload);
            }}
          >
            Search
          </Button>
        </Col>
        <Col flex="auto">
          .
          <Excelldownload data={result.current} data1={data1} />
        </Col>
        <Col flex="auto">
          .
          <ReactToPrint
            trigger={() => (
              <Button type="primary" style={{ width: "100%" }}>
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    );
  };
  const resultrender = (employee) => {
    let count = 0;
    let item = 0;
    let amount = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].Sales_person == employee.id) {
        count = count + 1;
        item = parseFloat(item) + parseFloat(data[i].quantity);
        amount = parseFloat(amount) + parseFloat(data[i].bill);
      }
    }
    if (count > 0) {
      result.current.push({
        name: employee.name,
        invoice: count,
        amount: formatter.format(parseFloat(amount).toFixed(2)),
      });
      return (
        <>
          <tr>
            <td>{employee.name}</td>
            <td style={{ textAlign: "right" }}>{count}</td>
            {/* <td>{item}</td> */}
            <td style={{ textAlign: "right" }}>
              {formatter.format(parseFloat(amount).toFixed(2))}
            </td>
          </tr>
          {/* <Panel
            header={<b> {employee.name}</b>}
            key={employee.id}
            extra={<b>{formatter.format(parseFloat(count).toFixed(2))}</b>}
            // showArrow={false}
            style={{ border: "none" }}
          ></Panel> */}
          {/* <Row>
            {employee.name}
            {count}
          </Row> */}
        </>
      );
    } else return <></>;
  };

  const Rendercontent = () => {
    if (loading) {
      return (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      );
    } else {
      result.current = [];
      return (
        <>
          <Row>
            <Col
              span={24}
              style={{ padding: "1rem", border: "1px solid whitesmoke" }}
            >
              {SwitchablePicker()}
              <div
                // className="invoice_print_fontSize"
                ref={componentRef}
                style={{ padding: "10px" }}
              >
                <Row
                  style={{
                    borderBottom: "2px solid lightgray",
                    paddingBottom: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <Col span={17} style={{ paddingTop: "10px" }}>
                    <small>
                      <div
                        style={{ lineHeight: "2.5px" }}
                        dangerouslySetInnerHTML={{
                          __html: businessprofile.address,
                        }}
                      ></div>
                    </small>
                  </Col>

                  <Col span={7} style={{ textAlign: "right" }}>
                    <img
                      src={businessprofile.logo}
                      style={{
                        maxHeight: "60px",
                        right: "0",
                      }}
                    />
                  </Col>
                </Row>

                <h3 style={{ textAlign: "center" }}>Sales person report</h3>
                <Row>
                  <Col span={12} style={{ textAlign: "left" }}>
                    {start.current != "" ? (
                      <>
                        From : {dateFormat(start.current, "mmmm dS, yyyy")}
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}

                    {end.current != "" ? (
                      <>
                        To : {dateFormat(end.current, "mmmm dS, yyyy")}
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {outletlist.map((outlet) => {
                      if (outlet.id == location.current)
                        return <>Outlet : {outlet.name}</>;
                    })}
                  </Col>
                </Row>
                <Divider />
                <Spin spinning={spinning}>
                  <table className="history_table" style={{ width: "100%" }}>
                    <tr>
                      <td>Name</td>
                      <td style={{ textAlign: "right" }}>Invoices</td>
                      {/* <td>Item</td> */}
                      <td style={{ textAlign: "right" }}>Amount</td>
                    </tr>
                    {employeeList.map((employee) => {
                      return resultrender(employee);
                    })}
                  </table>
                </Spin>

                {/* <RenderTable
                  List={data}
                  pageno={pageno}
                  page_size={page_size}
                  setreload={setreload}
                  reload={reload}
                  setloading={setloading}
                  total_data={total_data}
                /> */}
              </div>
            </Col>
          </Row>
        </>
      );
    }
  };
  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Report</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              {Rendercontent()}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
    Auth: state.auth,
    employeeList: state.employee.employeelist,
  };
};

export default connect(mapStateToProps, {
  getSalesReportByInvoiceCount,
  getSalesReport,
  getSalesPersonReport,
  getAllOutlet,
  getAllAccount,
  getAllEmployee,
})(SalesReport);
