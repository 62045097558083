import React, { useState } from "react";
import { connect } from "react-redux";

import { updateTag } from "../../../actions/tagAction";
import { Form, Input, Drawer, Button, Col, Row, message } from "antd";

const { TextArea } = Input;

const Edit = ({ details, setUpdatelist, updateTag }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    updateTag(details.id, values).then((res) => {
      setUpdatelist(false);
      message.success(values.name + " Has been Updated");
      setVisible(false);
    });
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        Edit
      </Button>

      <Drawer
        title="Edit Tag"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          initialValues={details}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="slug"
                label="Slug"
                rules={[
                  {
                    required: true,
                    message: "Please enter slug",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Please enter slug"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="meta_title" label="Meta Title">
                <Input style={{ width: "100%" }} placeholder="Meta Title" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="meta_description" label="Meta Description">
                <TextArea
                  rows={4}
                  style={{ width: "100%" }}
                  placeholder="Meta Description"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default connect(null, { updateTag })(Edit);
