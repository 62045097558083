import axios from "axios";

let baseUrl;

if (
  window.location.origin === "http://localhost:3000" ||
  window.location.origin === "http://localhost:3001"
) {
  baseUrl = "http://127.0.0.1:8000/";
  // baseUrl = "https://erp.anzaraltd.com/";
} else {
  //production
  baseUrl = window.location.origin;
  // baseUrl = "https://web-production-1f77.up.railway.app/";
}
export default axios.create({
  baseURL: baseUrl,
});
