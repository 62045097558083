import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getAllVariations } from "../../../actions/productDetails";

import {
  Divider,
  AutoComplete,
  Select,
  Skeleton,
  TreeSelect,
  Row,
  Col,
  Button,
} from "antd";
import Excelldownload from "./Excelldownload";
import PdfDownload from "./PdfDownload";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { result } from "lodash";

const SearchStock = ({ getAllVariations }) => {
  var formatter = new Intl.NumberFormat("en-IN");
  const [loading, setloading] = useState(true);
  const [reload, setreload] = useState(false);
  const [productList, setproductList] = useState([]);
  const componentRef = useRef();
  const Total_quantity = useRef(0);
  const Total_cost_of_goods_sold = useRef(0);
  const Total_amount = useRef(0);

  useEffect(() => {
    getAllVariations().then((result) => {
      setproductList(result);
      Total_quantity.current = 0;
      Total_cost_of_goods_sold.current = 0;
      Total_amount.current = 0;
      setloading(false);
    });
  }, [reload]);

  const rendertotalamount = (product) => {
    console.log(product);
    if (product.quantity > 0) {
      Total_quantity.current += parseFloat(product.quantity);
      Total_cost_of_goods_sold.current =
        parseFloat(Total_cost_of_goods_sold.current) +
        parseFloat(product.purchase_price * product.quantity);
      Total_amount.current =
        parseFloat(Total_amount.current) +
        parseFloat(product.selling_price * product.quantity);
      return (
        <tr>
          <td>{product.id}</td>
          <td>{product.barcode}</td>
          <td>{product.title}</td>

          <td>{product.main_category}</td>
          <td>{product.category_name}</td>

          <td>{product.Color}</td>
          <td>{product.Size}</td>
          <td>{product.Location}</td>
          <td style={{ textAlign: "right" }}>{product.stock_alart_amount}</td>

          <td style={{ textAlign: "right" }}>{product.quantity}</td>

          <td style={{ textAlign: "right" }}>
            {formatter.format(parseFloat(product.purchase_price).toFixed(2))}
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(parseFloat(product.selling_price).toFixed(2))}
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(
              parseFloat(product.purchase_price * product.quantity).toFixed(2)
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(
              parseFloat(product.selling_price * product.quantity).toFixed(2)
            )}
          </td>
        </tr>
      );
    }
  };

  const renderdata = () => {
    if (loading) {
      return (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      );
    } else {
      return (
        <>
          <table className="history_table">
            <tr style={{ fontWeight: "bold" }}>
              <td>Product ID</td>
              <td>Barcode</td>
              <td>Product Name</td>
              <td>Category</td>
              <td>Sub Category</td>

              <td>Color</td>
              <td>Size</td>
              <td>Location</td>
              <td style={{ textAlign: "right" }}>Stock Alert Amount</td>
              <td style={{ textAlign: "right" }}>Quantity</td>

              <td style={{ textAlign: "right" }}>Cost of Goods Sold</td>
              <td style={{ textAlign: "right" }}>Price</td>

              <td style={{ textAlign: "right" }}>Total Cost of Goods Sold</td>
              <td style={{ textAlign: "right" }}>Total Price</td>
            </tr>

            {productList.map((item, index) => {
              return <>{rendertotalamount(item)}</>;
            })}
            <tr>
              <td colSpan={9}>Total</td>
              <td style={{ textAlign: "right" }}>
                {formatter.format(
                  parseFloat(Total_quantity.current).toFixed(2)
                )}
              </td>
              <td colSpan={2}></td>
              <td style={{ textAlign: "right" }}>
                {formatter.format(
                  parseFloat(Total_cost_of_goods_sold.current).toFixed(2)
                )}
              </td>
              <td style={{ textAlign: "right" }}>
                {formatter.format(parseFloat(Total_amount.current).toFixed(2))}
              </td>
            </tr>
          </table>
        </>
      );
    }
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={4}>
          <Excelldownload data={productList} />
        </Col>
        <Col span={4}>
          {/* <ReactToPrint
            trigger={() => <Button type="primary">Print this out!</Button>}
            content={() => componentRef.current}
          /> */}
          {/* <PdfDownload
            data={productList}
            data1={productList}
            totalQuantity={totalQuantity}
            totalPrice={totalPrice}
            categoryName={categoryName}
            componentRef={componentRef}
            startDate={startDate}
            endDate={endDate}
          /> */}
        </Col>
      </Row>
      <br />
      {renderdata()}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    List: state.category.categorylist,
  };
};

export default connect(mapStateToProps, {
  getAllVariations,
})(SearchStock);
