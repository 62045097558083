import React, { useState } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { updatefeature } from "../../../actions/ecommercefeatureeAction";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Drawer,
  Button,
  Col,
  Row,
  Select,
  message,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const CreateNew = ({
  updatefeature,
  reloadpage,
  page,
  setreloadpage,
  data,
}) => {
  const initial = { remarks: "" };
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const showDrawer = () => {
    form.setFieldsValue(data);
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    updatefeature(data.id, values).then((res) => {
      form.resetFields();
      setreloadpage(!reloadpage);
      setVisible(false);
    });

    // message.success(values.name + " Has been added to your contact list");
  };

  return (
    <>
      <>
        <Button
          type="link"
          onClick={showDrawer}
          // style={{ marginBottom: "10px", float: "right" }}
        >
          Edit
        </Button>
        <Drawer
          title="Edit Freature"
          width="30%"
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initial}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="name" label="Name">
                  <Input placeholder="Please enter user name" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    </>
  );
};

export default connect(null, { updatefeature })(CreateNew);
