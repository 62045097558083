import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getAllLocation } from "../../../actions/warehouseAction";
import Report from "./Report";
import Excelldownload from "./Excelldownload";
import { DatePicker, Select, TreeSelect, Col, Space, Row, Button } from "antd";
import moment from "moment";
import ReactToPrint from "react-to-print";
import PdfDownload from "./PdfDownload";

const Maincontent = ({
  RoleList,
  businessprofile,
  locationlist,
  employeeList,
  getAllLocation,
  auth,
}) => {
  const { RangePicker } = DatePicker;
  const [updatelist, setUpdatelist] = useState(true);
  const [reload, setreload] = useState(false);
  var currentdate = new Date();
  var monthstart =
    currentdate.getFullYear() +
    "-" +
    (currentdate.getMonth() + 1) +
    "-" +
    "1" +
    "T00:00:00Z";
  var todayDate =
    currentdate.getFullYear() +
    "-" +
    (currentdate.getMonth() + 1) +
    "-" +
    currentdate.getDate() +
    "T00:00:00Z";
  const dateFormat = "YYYY-MM-DD";
  var date = new Date();
  const { Option } = Select;
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const startDate = useRef(monthstart);
  const endDate = useRef(todayDate);
  const employee = useRef("");
  const selectedLocation = useRef("");

  const [data, setData] = useState([]);
  const componentRef = useRef();

  useEffect(() => {
    getAllLocation();
  }, []);

  const onEmployeeChange = (value) => {
    employee.current = value;
    setreload(!reload);
  };
  return (
    <>
      <Row>
        <Col flex="auto">
          Date :
          <RangePicker
            picker="date"
            style={{ width: "100%" }}
            onChange={(value) => {
              if (value) {
                startDate.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                endDate.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                setreload(!reload);
              }
            }}
          />
        </Col>

        <Col flex="auto">
          Employee :
          <Select
            showSearch
            placeholder="Please select a Employee"
            style={{ width: "100%" }}
            onChange={(value) => onEmployeeChange(value)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="" select>
              All
            </Option>
            {employeeList.map((employee) => {
              return <Option value={employee.id}>{employee.name}</Option>;
            })}
          </Select>
        </Col>

        {auth.permissions.includes("HRM.Attendance Report_is_location") ||
        auth.superuser ? (
          <Col flex="auto">
            Location :
            <Select
              showSearch
              placeholder="Please select a Location"
              style={{ width: "100%" }}
              onChange={(value) => {
                selectedLocation.current = value;
                setreload(!reload);
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="" select>
                All
              </Option>
              {locationlist.map((location) => {
                return <Option value={location.id}>{location.name}</Option>;
              })}
            </Select>
          </Col>
        ) : (
          ""
        )}

        {/* <Col span={4}>
          Download:
          <Excelldownload
            data={data}
            data1={data}
            startDate={startDate}
            endDate={endDate}
          />
        </Col> */}
        <Col flex="auto">
          Print:
          <ReactToPrint
            trigger={() => (
              <Button
                style={{
                  width: "100%",
                }}
                type="primary"
              >
                Print this out!
              </Button>
            )}
            content={() => componentRef.current}
          />
          {/* <PdfDownload
            data={data}
            data1={data}
            componentRef={componentRef}
            startDate={startDate}
            endDate={endDate}
          /> */}
        </Col>
      </Row>

      <div className="site-layout-background main-frame">
        <div ref={componentRef} style={{ padding: "10px" }}>
          <Report
            startDate={startDate}
            componentRef={componentRef}
            endDate={endDate}
            employee={employee.current}
            data={data}
            setData={setData}
            updatelist={updatelist}
            setUpdatelist={setUpdatelist}
            reload={reload}
            setreload={setreload}
            selectedLocation={selectedLocation.current}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    employeeList: state.employee.employeelist,
    locationlist: state.warehouse.locationlist,
    businessprofile: state.settings.businessprofile,
  };
};

export default connect(mapStateToProps, { getAllLocation })(Maincontent);
