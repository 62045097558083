import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, AutoComplete, Select } from "antd";
import LoanList from "./LoanList";
import CreateNewLoan from "./CreateNewLoan";
import Excelldownload from "./Excelldownload";
import PdfDownload from "./PdfDownload";
import ReactToPrint from "react-to-print";
import moment from "moment";
const { Option } = Select;

const Maincontent = () => {
  const [reload, setreload] = useState(true);
  const [data, setData] = useState([]);
  const keyward = useRef("");
  const type = useRef("");
  const loanstatus = useRef("");
  const applicationstatus = useRef("");
  const pageno = useRef(1);
  const page_size = useRef(10);
  const componentRef = useRef();

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
      setreload(!reload);
    }
  };

  const SwitchablePicker = () => {
    return (
      <Row wrap={false} style={{ width: "100%" }}>
        <Col flex="auto">
          keyword :
          <AutoComplete
            placeholder="input search text"
            // onChange={onChange}
            onKeyUp={onChange}
            style={{ width: "100%", minWidth: "140px" }}
          />
        </Col>
        <Col flex="auto">
          Type
          <Select
            style={{ width: "100%" }}
            placeholder="Type"
            onChange={(value) => {
              type.current = value;
              setreload(!reload);
            }}
          >
            <Option value="">All</Option>
            <Option value="Advance">Advance</Option>
            <Option value="EMI">EMI</Option>
          </Select>
        </Col>
        <Col flex="auto">
          Application Status
          <Select
            style={{ width: "100%" }}
            placeholder="Status"
            onChange={(value) => {
              applicationstatus.current = value;
              setreload(!reload);
            }}
          >
            <Option value="">All</Option>
            <Option value="pending">Pending</Option>
            <Option value="paid">Paid</Option>
          </Select>
        </Col>
        <Col flex="auto">
          Loan Status
          <Select
            style={{ width: "100%" }}
            placeholder="Status"
            onChange={(value) => {
              loanstatus.current = value;
              setreload(!reload);
            }}
          >
            <Option value="">All</Option>
            <Option value="ongoing">Ongoing</Option>
            <Option value="finished">Finished</Option>
          </Select>
        </Col>
        <Col flex="auto">
          .
          <Excelldownload data={data} data1={data} />
        </Col>
        <Col flex="auto">
          .
          <ReactToPrint
            trigger={() => (
              <Button
                type="primary"
                style={{ width: "100%", marginRight: "10px" }}
              >
                Print this out!
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Col>
        <Col flex="auto"></Col>
      </Row>
    );
  };

  return (
    <>
      <div className="site-layout-background main-frame">
        <CreateNewLoan setUpdatelist={setreload} updatelist={reload} />

        <PdfDownload
          data={data}
          data1={data}
          componentRef={componentRef}
          date={moment()}
        />
        {SwitchablePicker()}
        <br></br>

        <LoanList
          reload={reload}
          setreload={setreload}
          setData={setData}
          data={data}
          pageno={pageno}
          page_size={page_size}
          keyward={keyward}
          applicationstatus={applicationstatus}
          loanstatus={loanstatus}
          type={type}
        />
      </div>
    </>
  );
};

export default connect()(Maincontent);
