import React, { useState } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { createpage } from "../../../actions/ecommercepageAction";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Drawer,
  Button,
  Col,
  Row,
  Select,
  message,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const CreateNew = ({ createpage, reloadpage, page, setreloadpage }) => {
  const initial = { remarks: "" };
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    values["is_campaign_page"] = true;
    createpage(values).then((res) => {
      form.resetFields();
      setreloadpage(!reloadpage);
      setVisible(false);
    });

    // message.success(values.name + " Has been added to your contact list");
  };

  return (
    <>
      <>
        <Button
          type="primary"
          onClick={showDrawer}
          style={{ marginBottom: "10px", float: "right" }}
        >
          <PlusOutlined /> New Page
        </Button>
        <Drawer
          title="Create a new Page"
          width="30%"
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initial}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="name" label="Name">
                  <Input placeholder="Please enter user name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="slug" label="Slug">
                  <Input placeholder="Please enter slug" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="meta_title" label="Meta title">
                  <Input placeholder="Please enter user name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="meta_description" label="Meta description">
                  <Input placeholder="Please enter user name" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    </>
  );
};

export default connect(null, { createpage })(CreateNew);
