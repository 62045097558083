import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ContactList from "./ContactList";
import Create from "./Create";
import {
  Row,
  Col,
  DatePicker,
  Space,
  Select,
  Button,
  AutoComplete,
  Divider,
  message,
  Popconfirm,
} from "antd";
import { deleteimage } from "../../../actions/ecommerceimageAction";
const { RangePicker } = DatePicker;
const { Option } = Select;

const Maincontent = ({ deleteimage }) => {
  const [updatelist, setUpdatelist] = useState(true);
  const [selectedimage, setselectedimage] = useState("");
  const start = useRef("");
  const end = useRef("");
  const role = useRef("");
  const keyward = useRef("");
  const pageno = useRef(1);
  const page_size = useRef(20);

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
    }
  };

  const confirm = (item) => {
    deleteimage(item.id).then(() => {
      setselectedimage("");
      setUpdatelist(!updatelist);
      message.success("Image Has been deleted");
    });
  };

  return (
    <>
      <div className="site-layout-background main-frame">
        <Row>
          <Col span={6}>
            <Create setUpdatelist={setUpdatelist} updatelist={updatelist} />
          </Col>
          <Col span={17} offset={1}>
            {selectedimage == "" ? (
              "Please select an image"
            ) : (
              <>
                <Row>
                  <Col span={6}>
                    <img
                      src={selectedimage.thumbnail}
                      style={{ maxHeight: "20vh", maxWidth: "20vh" }}
                    />
                  </Col>
                  <Col span={17}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <b>Image URL:</b>
                        </td>
                        <td>
                          {selectedimage.image}
                          <button
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedimage.image
                              );

                            }}
                          >
                            Copy
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Thumbnail URL:</b>
                        </td>
                        <td>
                          {selectedimage.thumbnail}
                          <button
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedimage.thumbnail
                              );
                            }}
                          >
                            Copy
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>alt:</b>
                        </td>
                        <td>{selectedimage.alt}</td>
                      </tr>
                    </table>
                    <Button danger style={{ marginRight: "10px" }}>
                      <Popconfirm
                        title="Are you sure to delete this image?"
                        onConfirm={() => confirm(selectedimage)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Link to="#">Delete</Link>
                      </Popconfirm>
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Divider />
        <ContactList
          updatelist={updatelist}
          setUpdatelist={setUpdatelist}
          pageno={pageno}
          page_size={page_size}
          start={start}
          end={end}
          keyward={keyward}
          role={role}
          setselectedimage={setselectedimage}
        />
      </div>
    </>
  );
};

export default connect(null, { deleteimage })(Maincontent);
