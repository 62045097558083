import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Drawer,
  Divider,
  Col,
  Row,
  Button,
  message,
  Popconfirm,
  Avatar,
} from "antd";
import { Link } from "react-router-dom";
import { deleteEmployee } from "../../../actions/employeeAction";
import EditEmployee from "./EditEmployee";
import GenerateId from "./GenerateId";
import { getSpecificUserEmployeeSalaryPayslipAll } from "../../../actions/PaySlipAction";
import moment from "moment";
import dateFormat from "dateformat";
import ReactToPrint from "react-to-print";
import PdfDownload from "./PdfDownload";
import Report from "./AttendanceReport/Details";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const EmployeeDetails = ({
  details,
  deleteEmployee,
  setUpdatelist,
  getSpecificUserEmployeeSalaryPayslipAll,
  Auth,
}) => {
  const [visible, setVisible] = useState(false);
  const [history_fetched, setHistory_fetched] = useState(false);
  const [payslip_list, setPayslip_list] = useState([]);
  const componentRef = useRef();

  var currentdate = new Date();
  var onlydateend =
    currentdate.getFullYear() +
    "-" +
    (currentdate.getMonth() + 1) +
    "-" +
    currentdate.getDate();
  // var onlydateend = currentdate.getFullYear() + "-" + "12" + "-" + "31";
  var onlydatestartmonth =
    currentdate.getFullYear() + "-" + (currentdate.getMonth() - 1) + "-" + "1";
  var onlydatestartyear = currentdate.getFullYear() + "-" + "01-01";

  const showDrawer = () => {
    getSpecificUserEmployeeSalaryPayslipAll(details.id).then(function (
      invoices
    ) {
      setPayslip_list(invoices);
      setHistory_fetched(true);
    });
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const confirm = () => {
    deleteEmployee(details.id);
    setVisible(false);
    setUpdatelist(false);
    message.success(details.name + " Has been deleted from your employee list");
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <Link to="#" onClick={showDrawer} key={details.id}>
        Details
      </Link>

      <Drawer
        width={1200}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          Information
        </p>
        <Row>
          <Col span={12}>
            <p className="site-description-item-profile-p">Personal</p>
            {console.log(details)}
            <Row>
              <Col span={24}>
                <Avatar size={64} src={details.photo} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="name" content={details.name} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Phone" content={details.phone} />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Emergency Phone"
                  content={details.emergency_phone}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Email" content={details.email} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Type" content={details.user_roleName} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Branch" content={details.branchName} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Salary" content={details.basic_salary} />
              </Col>

              <Col span={24}>
                <DescriptionItem title="Address" content={details.address} />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <DescriptionItem title="Shift" content={details.defaultShift} />
              </Col>
              <Col span={8}>
                <DescriptionItem
                  title="Default Entry Time"
                  content={details.defaultEntryTime}
                />
              </Col>
              <Col span={8}>
                <DescriptionItem
                  title="Default Exit Time"
                  content={details.defaultExitTime}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <DescriptionItem
                  title="Joining Date"
                  content={moment(details.joining_date).format("YYYY-MM-DD")}
                />
              </Col>
              <Col span={8}>
                <DescriptionItem
                  title="Resignation Date"
                  content={moment(details.resignation_date).format(
                    "YYYY-MM-DD"
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Report
              startDate={onlydatestartyear}
              startDateByYear={onlydatestartyear}
              endDate={onlydateend}
              employee={details.id}
              location={details.location}
            />
          </Col>
        </Row>

        {Auth.superuser ? (
          <Button danger style={{ marginRight: "10px" }}>
            <Popconfirm
              title="Are you sure to delete this contact?"
              onConfirm={confirm}
              okText="Yes"
              cancelText="No"
            >
              <Link to="#">Delete</Link>
            </Popconfirm>
          </Button>
        ) : (
          <></>
        )}
        {/* <EditEmployee details={details} setUpdatelist={setUpdatelist} /> */}

        <GenerateId details={details} setUpdatelist={setUpdatelist} />
        <Divider />
        <p className="site-description-item-profile-p">History</p>
        <Col span={8}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print this out!</Button>}
            content={() => componentRef.current}
          />
          <PdfDownload
            data={payslip_list}
            details={details}
            componentRef={componentRef}
          />
        </Col>
        {history_fetched ? (
          <>
            <br></br>
            <table className="history_table">
              <tr>
                <td>SL.</td>
                <td>Date</td>
                <td>Payment Month</td>
                <td>Leave</td>
                <td>Present</td>
                <td>Absent</td>
                <td>Late</td>
                <td>Total Overtime</td>
                <td>Total Incentive</td>
                <td>Total Allowance</td>
                <td>Fine</td>
                <td>Loan Adjustment</td>
                <td>Advance Adjustment</td>
                <td>Total Paid</td>
              </tr>

              {payslip_list.map((item, index) => {
                return (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{moment(item.paymentDate).format("DD-MM-YYYY")}</td>
                      <td>
                        {monthNames[item.salaryMonth - 1]}, {item.salaryYear}
                      </td>
                      <td>{item.leave}</td>
                      <td>{item.present}</td>
                      <td>{item.absent}</td>
                      <td>{item.late}</td>
                      <td>{item.overtimeTotal}</td>
                      <td>{item.incentiveTotal}</td>
                      <td>{item.dailyAllowanceTotal}</td>
                      <td>{item.fine}</td>
                      <td>{item.loan_adjustment}</td>
                      <td>{item.advance_adjustment}</td>
                      <td>{item.net_salary}</td>
                    </tr>
                  </>
                );
              })}
            </table>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </Drawer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  deleteEmployee,
  getSpecificUserEmployeeSalaryPayslipAll,
})(EmployeeDetails);
