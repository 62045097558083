import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllEmployeeLeave, getAllEmployeeLeaveP } from "../../../actions/employeeLeaveActions";
import { getAllLeaveType } from "../../../actions/leaveTypeActions";
import { getAllEmployee } from '../../../actions/employeeAction';
import Rendertable from "./Rendertable";

const EmployeeLeaveList = ({
  getAllEmployeeLeave,
  getAllEmployeeLeaveP,
  getAllLeaveType,
  getAllEmployee,
  employeeLeavelist,
  updatelist,
  setUpdatelist,
  setData,
  details,
  setdetails,
  keyward,
  start,
  end,
  pageno,
  page_size,
  reload,
  setReload
}) => {
  useEffect(() => {
    getAllLeaveType();
    getAllEmployee();
  }, []);

  useEffect(() => {
    getAllEmployeeLeaveP(keyward.current, start.current, end.current, pageno.current, page_size.current).then((result) => {
      // console.log(result);
      setdetails(result);
    });
  }, [reload]);

  return (
    <>
      {
        <Rendertable
          details={details}
          pageno={pageno}
          page_size={page_size}
          reload={reload}
          setReload={setReload}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeLeavelist: state.employeeLeave.employeeLeavelist,
  };
};

export default connect(mapStateToProps, {
  getAllEmployeeLeave,
  getAllLeaveType,
  getAllEmployee,
  getAllEmployeeLeaveP,
})(EmployeeLeaveList);
