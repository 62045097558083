import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Skeleton, Tree, Row, Col, Divider, Timeline } from "antd";
import { getlog } from "../../../actions/userlogAction";
import dateFormat from "dateformat";
import moment from "moment";

const List = ({
  getlog,
  ID,
  action_type,
  start,
  end,
  reload,
  setreload,
  keyword,
  content_type,
}) => {
  const [data, setdata] = useState();
  const [loading, setloading] = useState(true);
  const pageno = useRef(1);
  const page_size = useRef(100);

  useEffect(() => {
    getlog(
      pageno.current,
      page_size.current,
      content_type,
      ID,
      action_type,
      start,
      end,
      keyword
    ).then((result) => {
      setdata(result);
      setloading(false);
    });
  }, [reload]);

  const rendertable = (details) => {
    for (let key in details) {
      if (details.hasOwnProperty(key)) {
        console.log(key + ":");
        console.log("  Old Value: " + details[key].oldValue);
        console.log("  New Value: " + details[key].newValue);
      }
    }
  };

  if (loading) {
    return <Skeleton active />;
  } else {
    return (
      <>
        {/* <h3>{action_type}</h3> */}
        <Timeline>
          {data?.results.map((item) => {
            let event = item?.action.split(" ")[0];
            let parts = item?.action.split(/\(|\)/);
            // let details = {};
            // if (parts.length > 1) {
            //   let invoiceDetails = parts[1];
            //   let items = invoiceDetails.split(", "); // split by comma and space
            //   for (let i = 0; i < items.length; i++) {
            //     let parts = items[i].split(": "); // split by colon and space
            //     if (parts.length > 1) {
            //       let key = parts[0];
            //       let values = parts[1].split(" -> "); // split by arrow and space
            //       let oldValue = values[0];
            //       let newValue = values[1];
            //       details[key] = {
            //         oldValue: oldValue,
            //         newValue: newValue,
            //       };
            //     }
            //   }
            // }
            return (
              <Timeline.Item
                color={
                  item.action_type == "create"
                    ? "green"
                    : item.action_type == "update"
                    ? "orange"
                    : "red"
                }
              >
                <Row>
                  <Col flex="20%">
                    <h5>
                      {event} by {item.created_by}
                    </h5>
                  </Col>
                  <Col flex="60%">{item.action}</Col>
                  <Col flex="20%">
                    <h5>
                      {moment(item.timestamp).format("YYYY-MM-DD hh:mm A")}
                      {/* {item.timestamp} */}
                    </h5>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>{item.action}</Col>
                </Row> */}

                {/* <table style={{ width: "100%" }}>
                  {Object.entries(details).map(([key, value]) => {
                    return (
                      <>
                        <tr>
                          <td>{key}</td>
                          <td>{value.oldValue}</td>
                          <td>{value.newValue}</td>
                        </tr>
                      </>
                    );
                  })}
                </table> */}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </>
    );
  }
};

export default connect(null, {
  getlog,
})(List);
