import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styledMultiDataSet = [
  {
    columns: [
      {
        value: "Headings",
        widthPx: 160,
        style: { font: { sz: "24", bold: true } },
      },
      {
        value: "Text Style",
        widthPx: 180,
        style: { font: { sz: "24", bold: true } },
      },
      {
        value: "Colors",
        style: { font: { sz: "24", bold: true } }, // if no width set, default excel column width will be used ( 64px )
      },
    ],
    data: [
      [
        { value: "H1", style: { font: { sz: "24", bold: true } } },
        { value: "Bold", style: { font: { bold: true } } },
        {
          value: "Red",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
          },
        },
      ],
      [
        { value: "H2", style: { font: { sz: "18", bold: true } } },
        { value: "underline", style: { font: { underline: true } } },
        {
          value: "Blue",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } },
          },
        },
      ],
      [
        { value: "H3", style: { font: { sz: "14", bold: true } } },
        { value: "italic", style: { font: { italic: true } } },
        {
          value: "Green",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } },
          },
        },
      ],
      [
        { value: "H4", style: { font: { sz: "12", bold: true } } },
        { value: "strike", style: { font: { strike: true } } },
        {
          value: "Orange",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFF86B00" } },
          },
        },
      ],
      [
        { value: "H5", style: { font: { sz: "10.5", bold: true } } },
        { value: "outline", style: { font: { outline: true } } },
        {
          value: "Yellow",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
          },
        },
      ],
      [
        { value: "H6", style: { font: { sz: "7.5", bold: true } } },
        { value: "shadow", style: { font: { shadow: true } } },
        {
          value: "Light Blue",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
          },
        },
      ],
    ],
  },
];

const multiDataSet = [
  {
    columns: [
      { value: "Name", widthPx: 50 }, // width in pixels
      { value: "Salary", widthCh: 20 }, // width in charachters
      { value: "Sex", widthPx: 60, widthCh: 20 }, // will check for width in pixels first
    ],
    data: [
      ["Johnson", 30000, "Male"],
      ["Monika", 355000, "Female"],
      ["Konstantina", 20000, "Female"],
      ["John", 250000, "Male"],
      ["Josef", 450500, "Male"],
    ],
  },
  {
    xSteps: 1, // Will start putting cell with 1 empty cell on left most
    ySteps: 5, //will put space of 5 rows,
    columns: ["Name", "Department"],
    data: [
      ["Johnson", "Finance"],
      ["Monika", "IT"],
      ["Konstantina", "IT Billing"],
      ["John", "HR"],
      ["Josef", "Testing"],
    ],
  },
];

const Excelldownload = ({ data, data1 }) => {
  let fileName = "Sales Report";

  return (
    <div>
      <ExcelFile
        element={<Button type="primary">Download</Button>}
        filename={fileName}
      >
        {/* <ExcelSheet dataSet={multiDataSet} name="Sales Report" /> */}
        <ExcelSheet data={data} name="Sales Report">
          <ExcelColumn label="Invoice No." value="invoice_number" />
          <ExcelColumn label="Date" value="issue_date" />
          <ExcelColumn label="Customer Name" value="contact_name" />
          <ExcelColumn label="Customer Phone" value="contact_phone" />
          <ExcelColumn label="Customer Address" value="contact_address" />
          <ExcelColumn label="Barcode" value="barcode" />
          <ExcelColumn label="Title" value="title" />
          <ExcelColumn label="Category" value="parent_category" />
          <ExcelColumn label="Sub Category" value="category" />
          <ExcelColumn label="Color" value="Color" />
          <ExcelColumn label="Size" value="Size" />
          <ExcelColumn label="QTY" value="item_quantity" />
          <ExcelColumn label="Rate" value="price" />
          <ExcelColumn label="Sub total" value="sub_total" />
          <ExcelColumn label="Discount" value="discount" />
          <ExcelColumn label="Vat" value="vat" />
          <ExcelColumn label="Bill" value="bill" />
          <ExcelColumn label="Payment" value="payment" />
          <ExcelColumn label="Due" value="due" />
          <ExcelColumn label="Method" value="method" />
          <ExcelColumn label="Amount" value="amount" />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};

export default Excelldownload;
