import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { getProductSearchResult } from "../../actions/productDetails";
import { DeleteOutlined } from "@ant-design/icons";

import { createWordrobe, createWordrobeItem } from "../../actions/wordrobe";
import { updateVariationfromSrock } from "../../actions/variableProductAction";
import { getAllWarehouse } from "../../actions/warehouseAction";

import ContctSearch from "./ContctSearch";
import history from "../../history";

import {
  Divider,
  AutoComplete,
  Row,
  Col,
  Image,
  InputNumber,
  Affix,
  Button,
  message,
  DatePicker,
  Space,
  Select,
  Input,
  TreeSelect,
} from "antd";
const { Option } = Select;

const SearchStock = ({
  search,
  cartItems,
  setCart,
  cart,
  createWordrobe,
  createWordrobeItem,
  updateVariationfromSrock,
  getAllWarehouse,
  WarehouseList,
  Auth,
  location,
}) => {
  const services = useRef([]);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "BDT",
  });

  const [servicetrigger, setservicetrigger] = useState(false);

  var currentdate = new Date();

  getAllWarehouse();
  var invoiceNumber =
    currentdate.getDate().toString() +
    (currentdate.getMonth() + 1).toString() +
    currentdate.getFullYear().toString() +
    currentdate.getHours().toString() +
    currentdate.getMinutes().toString() +
    currentdate.getSeconds().toString();
  const bill = useRef({
    Contact: null,
    Discount: 0,
    subTotal: 0,
    Total: 0,
    Due: 0,
    Paid: 0,
    PaymentMethod: "Cash",
    Issue: "",
    Deliver: "",
    model_name: "",
    makeup_artist_name: "",
    rent: 0,
    wordrobe_number: invoiceNumber,
    photographer_name: "",
    company_profile_link: "",
    reference: "",
    status: "",
  });

  useEffect(() => {
    let Total = 0;
    cartItems.current.map((item) => {
      Total += parseFloat(item.total_price);
    });
    bill.current.subTotal = parseFloat(Total);
    bill.current.Due =
      parseFloat(bill.current.rent) - parseFloat(bill.current.Paid);
    setCart(false);
  }, [cart]);

  const submitOrder = () => {
    let promises = [];
    if (
      bill.current.Contact != null &&
      bill.current.Deliver != "" &&
      location.current != ""
    ) {
      const formData = new FormData();
      formData.append("contact", bill.current.Contact);
      formData.append("rent", bill.current.rent.toFixed(2));
      formData.append("due", bill.current.Due.toFixed(2));
      formData.append("payment", bill.current.Paid.toFixed(2));
      formData.append("product_cost", bill.current.subTotal.toFixed(2));
      formData.append("Payment_method", bill.current.PaymentMethod);
      formData.append("issue_date", bill.current.Issue);
      if (bill.current.Deliver != "") {
        formData.append("delivery_date", bill.current.Deliver);
      }
      formData.append("wordrobe_number", bill.current.wordrobe_number);

      formData.append("location", location.current);
      formData.append("remarks", bill.current.remarks);
      formData.append("reference", bill.current.reference);
      formData.append("makeup_artist_name", bill.current.makeup_artist_name);
      formData.append("model_name", bill.current.model_name);
      formData.append("photographer_name", bill.current.photographer_name);
      formData.append(
        "company_profile_link",
        bill.current.company_profile_link
      );
      formData.append("status", "Delivered to sponsor");
      formData.append("data", "");
      promises.push(
        createWordrobe(formData).then(function (result) {
          async function someProcedure() {
            cartItems.current.map((item) => {
              const formData = new FormData();
              formData.append("wordrobe", result.id);
              formData.append("product", item.id);
              formData.append("price", item.price);
              formData.append("quantity", item.quantity);
              formData.append("data", "");
              promises.push(createWordrobeItem(formData));
            });
            cartItems.current.map((item) => {
              const formData = new FormData();
              formData.append("wordrobe", result.id);
              formData.append("product", item.id);
              formData.append("price", item.price);
              formData.append("data", "");
              var newstock = item.limit - item.quantity;
              formData.append("quantity", newstock);
              promises.push(
                updateVariationfromSrock(item.id, formData, newstock)
              );
            });
            return "done";
          }
          someProcedure().then(() => {
            Promise.all(promises).then(() => {
              message.success("Order has been taken");
              history.push("/wordrobe");
            });
          });
        })
      );
    } else {
      alert("Please insert all the required fields");
    }
  };

  return (
    <>
      <Row style={{ minHeight: "23vh" }}>
        <Col span={10}>
          <h3>Contact Details</h3>
          <ContctSearch bill={bill} />
        </Col>
        <Col span={14}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>
                  <span style={{ color: "red" }}>*</span>Location
                </h3>
              </Col>
              <Col span={12} offset={1}>
                <Select
                  style={{ width: "100%" }}
                  // defaultValue={bill.current.location}
                  defaultValue={Auth.profile.branch.id}
                  disabled={
                    Auth.permissions.includes(
                      "Sponsorship.Add new sponsor_is_location"
                    ) || Auth.superuser
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    cartItems.current = [];
                    location.current = e;
                    setCart(true);
                  }}
                >
                  {WarehouseList.map((warehouse) => {
                    return (
                      <Option value={warehouse.id}>{warehouse.name}</Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Order No.</h3>
              </Col>
              <Col span={12} offset={1}>
                <InputNumber
                  value={bill.current.wordrobe_number}
                  disabled
                  style={{ color: "black" }}
                />
              </Col>
            </Row>

            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Reference</h3>
              </Col>
              <Col span={12} offset={1}>
                <Input
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    bill.current.reference = value.target.value;
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Facebook Link</h3>
              </Col>
              <Col span={12} offset={1}>
                <Input
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    bill.current.company_profile_link = value.target.value;
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Photographer name / FB link</h3>
              </Col>
              <Col span={12} offset={1}>
                <Input
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    bill.current.photographer_name = value.target.value;
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Model name / FB link</h3>
              </Col>
              <Col span={12} offset={1}>
                <Input
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    bill.current.model_name = value.target.value;
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Makeup Artist name / FB link</h3>
              </Col>
              <Col span={12} offset={1}>
                <Input
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    bill.current.makeup_artist_name = value.target.value;
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={11}>
          <h1>Description</h1>
        </Col>

        <Col span={3} offset={1} style={{ textAlign: "center" }}>
          <h1>Quantity</h1>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <h1>Price</h1>
        </Col>
        <Col span={5} style={{ textAlign: "center" }}>
          <h1> Total (BDT) </h1>
        </Col>
      </Row>
      {cartItems.current.map((item) => {
        const index = cartItems.current.indexOf(item);
        return (
          <>
            <Row
              style={{
                borderRadius: "10px",
                padding: "10px",
                background: "#F0F2F5",
                marginBottom: "2px",
              }}
            >
              <Col span={11}>
                <p>{item.title}</p>
                <p>
                  color: {item.color} / size: {item.size}
                </p>
              </Col>

              <Col span={3} style={{ textAlign: "center", margin: "auto" }}>
                <InputNumber
                  min={1}
                  value={cartItems.current[index].quantity}
                  onChange={(e) => {
                    cartItems.current[index].quantity = e;
                    cartItems.current[index].total_price =
                      e * cartItems.current[index].price;
                    console.log(cartItems.current[index]);
                    setCart(true);
                  }}
                  max={cartItems.current[index].limit}
                />
              </Col>
              <Col span={3} style={{ textAlign: "center", margin: "auto" }}>
                {cartItems.current[index].price}
              </Col>

              <Col span={5} style={{ textAlign: "center", margin: "auto" }}>
                {item.total_price}
                <DeleteOutlined
                  style={{ color: "Red", float: "right", margin: "auto" }}
                  onClick={(e) => {
                    cartItems.current.splice(index, 1);
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
          </>
        );
      })}

      <Row>{search()}</Row>

      <Divider />
      <Row>
        <Col span={14}></Col>
        <Col span={10} style={{ marginTop: "1rem" }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Product price</h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  value={bill.current.subTotal}
                  disabled
                  style={{ color: "black" }}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3> Rent</h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  onChange={(e) => {
                    bill.current.rent = e;
                    setCart(true);
                  }}
                />
              </Col>
            </Row> */}

            {/* <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Payment method</h3>
              </Col>
              <Col span={12} offset={1}>
                <Select
                  defaultValue="Cash"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    bill.current.PaymentMethod = e;
                    setCart(true);
                  }}
                >
                  <Option value="Card">Card</Option>
                  <Option value="Bkash">Bkash</Option>
                  <Option value="Nagad">Nagad</Option>
                  <Option value="Cash">Cash</Option>
                  <Option value="Bank">Bank</Option>
                </Select>
              </Col>
            </Row> */}
            {/* <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Paid</h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  onChange={(e) => {
                    bill.current.Paid = e;

                    setCart(true);
                  }}
                />
              </Col>
            </Row> */}
            {/* <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Due</h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  value={bill.current.Due}
                  disabled
                  style={{ color: "black" }}
                />
              </Col>
            </Row> */}
            <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>
                  <span style={{ color: "red" }}>*</span>Return date
                </h3>
              </Col>
              <Col span={12} offset={1}>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e != null) {
                      bill.current.Deliver = e.format("YYYY-MM-DD");
                    }
                    setCart(true);
                  }}
                />
              </Col>
            </Row>

            <Button
              type="primary"
              onClick={submitOrder}
              style={{ float: "right" }}
            >
              Confirm Order
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    List: state.ProductDetails.productdetails,
    Contacts: state.contacts.contactlistsearchresult,
    WarehouseList: state.warehouse.locationlist,
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  getProductSearchResult,
  getAllWarehouse,
  createWordrobe,
  createWordrobeItem,
  updateVariationfromSrock,
  getAllWarehouse,
})(SearchStock);
