import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
// import Maincontent from "./Maincontent";
import { getAllpage, getAllCampaignPage, deletepage } from "../../../actions/ecommercepageAction";
import { deletefeature } from "../../../actions/ecommercefeatureeAction";
import { deletefeatureitem } from "../../../actions/ecommercefeatureitemAction";
import PageDetails from "./PageDetails";
import FeatureDetails from "./FeatureDetails";
import CreateFeature from "./CreateFeature";
import CreatePage from "./CreatePage";
import CreateFeatureItem from "./CreateFeatureItem";
import EditFeatureItem from "./EditFeatureItem";
import EditFeature from "./EditFeature";
import EditPage from "./EditPage";
import { Link } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Spin,
  Input,
  Form,
  Divider,
  Button,
  Card,
  Popconfirm,
  message,
} from "antd";
import employeeSalaryPaymentReducers from "../../../reducers/employeeSalaryPaymentReducers";
const { Content } = Layout;

const Index = ({
  getAllCampaignPage,
  deletepage,
  deletefeature,
  deletefeatureitem,
}) => {
  const [reloadpage, setreloadpage] = useState(false);
  const [loadingpage, setloadingpage] = useState(false);
  const [showspin, setshowspin] = useState(false);
  const [pages, setpages] = useState([]);
  const selectedpage = useRef("");
  const selectedfeature = useRef("");

  useEffect(() => {
    // console.log("loading");
    setshowspin(true);
    getAllCampaignPage().then((res) => {
      setpages(res);
      if (selectedpage.current != "") {
        res.map((page) => {
          if (page.id == selectedpage.current.id) {
            selectedpage.current = page;
            if (selectedfeature.current != "") {
              page.features.map((feature) => {
                if (feature.id == selectedfeature.current.id) {
                  selectedfeature.current = feature;
                  setshowspin(false);
                }
              });
            } else {
              setshowspin(false);
            }
          }
        });
      } else {
        setshowspin(false);
      }
      setshowspin(false);
    });
  }, [reloadpage]);

  const confirm = (type, item) => {
    if (type == "featureitem") {
      deletefeatureitem(item.id).then(() => {
        setreloadpage(!reloadpage);
        message.success(item.title + " Has been deleted");
      });
    } else if (type == "feature") {
      if (selectedfeature.current.id == item.id) {
        selectedfeature.current = "";
      }
      deletefeature(item.id).then(() => {
        setreloadpage(!reloadpage);
        message.success(item.name + " Has been deleted");
      });
    } else {
      if (selectedpage.current.id == item.id) {
        selectedpage.current = "";
      }
      deletepage(item.id).then(() => {
        setreloadpage(!reloadpage);
        message.success(item.name + " Has been deleted");
      });
    }
  };

  const renderfeatureitem = (data) => {
    return data?.items.map((item, index) => {
      return (
        <Card>
          <Row>
            <Col span={20}>
              <Row>
                {item.url ? (
                  <>
                    <Col span={8}>
                      <img width="100" src={item.url} />
                    </Col>
                  </>
                ) : (
                  ""
                )}

                <Col span={16}>
                  {item.title}
                  <br></br>
                  {item.sub_title}
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <EditFeatureItem
                data={item}
                reloadpage={reloadpage}
                setreloadpage={setreloadpage}
              />
              <Button type="link" danger style={{ marginRight: "10px" }}>
                <Popconfirm
                  title="Are you sure to delete this item?"
                  onConfirm={() => confirm("featureitem", item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Link to="#">Delete</Link>
                </Popconfirm>
              </Button>
            </Col>
          </Row>
        </Card>
      );
    });
  };

  const renderfeature = (data) => {
    return data?.features.map((item) => {
      return (
        <Card
          style={{
            background: "#f8f8f8",
          }}
          onClick={() => {
            selectedfeature.current = item;
            setloadingpage(!loadingpage);
          }}
        //   title={item.name}
        >
          <Row>
            <Col span={20}>
              <span style={{ fontWeight: "600", textDecoration: "underline" }}>{item.name}</span>
              <br />
              {item?.title}
            </Col>
            <Col span={4}>
              <EditFeature
                data={item}
                reloadpage={reloadpage}
                setreloadpage={setreloadpage}
              />
              <Button type="link" danger style={{ marginRight: "10px" }}>
                <Popconfirm
                  title="Are you sure to delete this feature?"
                  onConfirm={() => confirm("feature", item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Link to="#">Delete</Link>
                </Popconfirm>
              </Button>
            </Col>
          </Row>
        </Card>
      );
    });
  };

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Campaign Pages</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              <Spin spinning={showspin}>
                <Row>
                  <Col
                    span={6}
                    style={{
                      background: "#f8f8f8",
                      //   borderRight: "1px solid gray",
                      padding: "10px",
                    }}
                  >
                    Pages
                    <CreatePage
                      reloadpage={reloadpage}
                      setreloadpage={setreloadpage}
                    />
                    <Divider />
                    {pages.map((page) => {
                      return (
                        <Card
                          onClick={() => {
                            selectedpage.current = page;
                            selectedfeature.current = "";
                            setloadingpage(!loadingpage);
                          }}
                        //   title={item.name}
                        >
                          <Row>
                            <Col span={18}>
                              {page.name} ({page.id})
                            </Col>
                            <Col>
                              <EditPage
                                data={page}
                                reloadpage={reloadpage}
                                setreloadpage={setreloadpage}
                              />
                              <Button
                                type="link"
                                danger
                                style={{ marginRight: "10px" }}
                              >
                                <Popconfirm
                                  title="Are you sure to delete this page?"
                                  onConfirm={() => confirm("page", page)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Link to="#">Delete</Link>
                                </Popconfirm>
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      );
                    })}
                  </Col>
                  <Col
                    span={8}
                    style={{
                      padding: "10px",
                    }}
                  >
                    {selectedpage.current != "" ? (
                      <>
                        {selectedpage.current.name}

                        <CreateFeature
                          page={selectedpage.current}
                          reloadpage={reloadpage}
                          setreloadpage={setreloadpage}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <Divider />
                    {selectedpage.current != "" ? (
                      <>{renderfeature(selectedpage.current)}</>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    span={10}
                    style={{
                      background: "#f8f8f8",

                      padding: "10px",
                    }}
                  >
                    {selectedfeature.current != "" ? (
                      <>
                        {selectedpage.current.name} {" > "}{" "}
                        {selectedfeature.current.name}

                        <CreateFeatureItem
                          feature={selectedfeature.current}
                          reloadpage={reloadpage}
                          setreloadpage={setreloadpage}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <Divider />
                    {selectedfeature.current != "" ? (
                      <>{renderfeatureitem(selectedfeature.current)}</>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Spin>
            </div>
            {/* <Maincontent /> */}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default connect(null, {
  getAllCampaignPage,
  deletepage,
  deletefeature,
  deletefeatureitem,
})(Index);
