import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllEmployeeSalary, getAllEmployeeSalaryP } from "../../../actions/employeeSalaryActions";
import Rendertable from "./Rendertable";

const EmployeeSalaryList = ({
  getAllEmployeeSalary,
  getAllEmployeeSalaryP,
  AllemployeeSalarylist,
  details,
  setdetails,
  keyward,
  pageno,
  page_size,
  reload,
  setReload
}) => {

  useEffect(() => {
    getAllEmployeeSalaryP(keyward.current, pageno.current, page_size.current).then((result) => {
      setdetails(result);
    });
  }, [reload]);

  // console.log(AllemployeeSalarylist);

  return (
    <>
      {
        <Rendertable
          details={details}
          pageno={pageno}
          page_size={page_size}
          reload={reload}
          setReload={setReload}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    AllemployeeSalarylist: state.employeeSalary.employeeSalarylist,
  };
};

export default connect(mapStateToProps, {
  getAllEmployeeSalary,
  getAllEmployeeSalaryP,
})(EmployeeSalaryList);
