import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excelldownload = ({ data, data1 }) => {
  return (
    <ExcelFile
      element={
        <Button type="primary" style={{ width: "98%" }}>
          Download
        </Button>
      }
    >
      <ExcelSheet data={data} name="Products">
        <ExcelColumn label="SL" value="sl" />
        <ExcelColumn label="ProductName" value="ProductName" />
        {/* <ExcelColumn label="ProductDescription" value="ProductDescription" /> */}
        <ExcelColumn label="StyleCode" value="StyleCode" />
        <ExcelColumn label="Barcode" value="Barcode" />
        {/* <ExcelColumn label="Size" value="Size" /> */}
        <ExcelColumn label="Color" value="Color" />
        <ExcelColumn label="Quantity" value="Quantity" />
        <ExcelColumn label="MRP" value="MRP" />
        <ExcelColumn label="ProductID" value="ProductID" />
        <ExcelColumn label="ProductImage" value="ProductImage" />
        <ExcelColumn label="ProductImage1" value="ProductImage1" />
        <ExcelColumn label="ProductImage2" value="ProductImage2" />
        <ExcelColumn label="ProductImage3" value="ProductImage3" />
        <ExcelColumn label="ColorID" value="ColorID" />
        <ExcelColumn label="url" value="url" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Excelldownload;
