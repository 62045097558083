import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
// import Maincontent from "./Maincontent";
import { getSpecificfeature } from "../../../actions/ecommercefeatureeAction";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Spin,
  Input,
  Form,
  Divider,
  Button,
} from "antd";
const { Content } = Layout;

const FeatureDetails = ({
  getSpecificfeature,
  feature,
  loadingpage,
  setloadingpage,
}) => {
  const [reloadpage, setreloadpage] = useState(false);
  //   const [loadingpage, setloadingpage] = useState(true);
  const [datas, setdatas] = useState([]);

  useEffect(() => {
    alert("items");
    // setloadingpage(true);
    getSpecificfeature(feature.id).then((res) => {
      setdatas(res);
      //   setloadingpage(false);
    });
  }, [reloadpage]);

  const renderData = () => {
    return feature?.items.map((item) => {
      return <h1>{item.id}</h1>;
    });
  };
  if (loadingpage) {
    return <>loading...........</>;
  } else
    return (
      <>
        <Row>
          <Col span={24}>
            Features
            <Divider />
            <Spin spinning={loadingpage}>{renderData()}</Spin>
          </Col>
        </Row>
      </>
    );
};

export default connect(null, { getSpecificfeature })(FeatureDetails);
