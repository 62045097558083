import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Skeleton, Tree, Row, Col, Divider } from "antd";
import { getAlljournalvoucherP } from "../../../actions/accounting/JournalVoucher";
import RenderTable from "./RenderTable";

const List = ({
  getAlljournalvoucherP,
  reload,
  setreload,
  showlist,
  pageno,
  page_size,
  start,
  end,
  keyward,
}) => {
  const [data, setdata] = useState();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    getAlljournalvoucherP(
      start.current,
      end.current,
      keyward.current,
      pageno.current,
      page_size.current
    ).then((result) => {
      setdata(result);
      setloading(false);
    });
  }, [reload]);

  if (loading) {
    return <Skeleton active />;
  } else {
    return (
      <>
        <RenderTable
          List={data}
          reload={reload}
          setreload={setreload}
          pageno={pageno}
          page_size={page_size}
        />
      </>
    );
  }
};

export default connect(null, {
  getAlljournalvoucherP,
})(List);
