import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import ReactToPrint from "react-to-print";

import moment from "moment";
import { getAllAccount } from "../../../actions/accountsAction";
import { getAllLocation } from "../../../actions/warehouseAction";
import { getAlljournalvoucheritemsByAccount } from "../../../actions/accounting/JournalVoucheritems";
import { getAllreceivevoucheritemsByAccount } from "../../../actions/accounting/ReceiveVoucheritems";
import { getAllpaymentvoucheritemByAccount } from "../../../actions/accounting/paymentVoucheritems";
import { getAllInvoicesByAccount } from "../../../actions/invoiceItem";
import { getAllPayslipByAccount } from "../../../actions/PaySlipAction";
import { getJournalSearchResult } from "../../../actions/journalAction";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Select,
  Divider,
  Skeleton,
  Button,
  Spin,
  Collapse,
} from "antd";
const { Panel } = Collapse;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const AccountsStatus = ({
  getJournalSearchResult,
  getAllAccount,
  getAlljournalvoucheritemsByAccount,
  getAllreceivevoucheritemsByAccount,
  getAllpaymentvoucheritemByAccount,
  getAllInvoicesByAccount,
  getAllPayslipByAccount,
  businessprofile,
  getAllLocation,
  locationlist,
}) => {
  const componentRef = useRef();
  const [accounts, setAccounts] = useState([]);
  const accountNo = useRef();
  const [journalsVoucher, setJournalsVoucher] = useState([]);
  const [receiveVoucher, setReceiveVoucher] = useState([]);
  const [paymentVoucher, setPaymentVoucher] = useState([]);
  const [journals, setJournals] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [payslips, setPayslips] = useState([]);
  const [type, setType] = useState("date");
  const [loading, setloading] = useState(true);
  const [reload, setreload] = useState(true);
  const [dataFetched, setDateFetched] = useState(false);
  var currentdate = new Date();
  var today =
    currentdate.getFullYear() +
    "-" +
    (currentdate.getMonth() + 1) +
    "-" +
    currentdate.getDate() +
    "T23:59:59";
  var accountsstart = "";
  if (currentdate.getMonth() + 1 > 7) {
    accountsstart =
      currentdate.getFullYear() + "-07-" + currentdate.getDate() + "T00:00:01";
  } else {
    accountsstart =
      currentdate.getFullYear() -
      1 +
      "-07-" +
      currentdate.getDate() +
      "T00:00:01";
  }

  const openingDate = useRef(accountsstart);
  const start = useRef("");
  const end = useRef(today);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const totalBranch = useRef(0);
  const totalcash = useRef(0);

  const totaldebit = useRef(0);
  const totalcredit = useRef(0);
  const endingBalance = useRef(0);

  const openingcredit = useRef(0);
  const openingdebit = useRef(0);
  const openingbalance = useRef(0);

  useEffect(() => {
    getAllLocation();
    getAllAccount().then((result) => {
      let account = [];
      for (let i = 0; i < result.length; i++) {
        account.push(result[i]);
      }
      setAccounts(account);
      setDateFetched(true);
    });
  }, []);

  useEffect(() => {
    setloading(true);
    if (openingDate.current != "" && end.current != "") {
      getJournalSearchResult(
        "",
        openingDate.current,
        end.current,
        // accountNo.current
        "",
        1000
      ).then((result) => {
        let res = [];
        endingBalance.current = 0;
        openingbalance.current = 0;
        totaldebit.current = 0;
        totalcredit.current = 0;
        openingdebit.current = 0;
        openingcredit.current = 0;

        for (let i = 0; i < result.length; i++) {
          if (result[i].Subgroup == "Cash" || result[i].Subgroup == "Bank") {
            res.push(result[i]);
          }
        }
        res.reverse();
        setJournals(res);
        endingBalance.current = totaldebit.current - totalcredit.current;
        openingbalance.current = openingdebit.current - openingcredit.current;
        setloading(false);
        console.log(journals);
      });
    }
  }, [reload]);

  const SwitchablePicker = () => {
    return (
      <Row>
        {/* <Col flex="auto">
          <h3>Date:</h3>
          <DatePicker
            style={{ width: "100%" }}
            onChange={(value) => {
              if (value) {
                openingDate.current = value.format("YYYY") + "-07-01T00:00:00";
                // start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value.format("YYYY-MM-DD") + "T23:59:59";
                setStartTime(value.format("YYYY-MM-DD") + "T00:00:00");
                setEndTime(value.format("YYYY-MM-DD") + "T23:59:59");
                setreload(!reload);
              }
            }}
          />
        </Col> */}

        <Col flex="auto">
          {/* <h3>.</h3> */}

          <ReactToPrint
            trigger={() => (
              <Button
                type="primary"
                // style={{ width: "100%" }}
              >
                Print Report
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    );
  };

  const Rendercontent = () => {
    return (
      <>
        <Row>
          <Col
            span={24}
            style={{ padding: "1rem", border: "1px solid whitesmoke" }}
          >
            {SwitchablePicker()}
            <Divider />
            {openingDate.current != "" && end.current != "" ? (
              <Spin spinning={loading}>
                <div ref={componentRef}>
                  <h3
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "10px",
                      background: "lightgray",
                    }}
                  >
                    Date :{" "}
                    {currentdate.getDate() +
                      "-" +
                      (currentdate.getMonth() + 1) +
                      "-" +
                      currentdate.getFullYear() +
                      " " +
                      currentdate.getHours() +
                      ":" +
                      currentdate.getMinutes()}
                  </h3>
                  <table className="account_table">
                    <tr style={{ background: "lightgray" }}>
                      <th
                        colSpan={4}
                        style={{ padding: "10px", textAlign: "center" }}
                      >
                        CASH
                      </th>
                    </tr>
                    <tr style={{ background: "#f0f0f0" }}>
                      <th
                        style={{
                          textAlign: "left",
                          padding: "10px",
                          width: "40%",
                        }}
                      >
                        Account Name
                      </th>
                      {/* <th>Location</th> */}
                      <th>Account Number</th>
                      <th style={{ textAlign: "right", padding: "10px" }}>
                        Amount
                      </th>
                    </tr>
                    {RenderMethod("Cash")}
                  </table>
                  <br></br>
                  <table className="account_table">
                    <tr style={{ background: "lightgray" }}>
                      <th
                        colSpan={4}
                        style={{ padding: "10px", textAlign: "center" }}
                      >
                        BANK
                      </th>
                    </tr>
                    <tr style={{ background: "#f0f0f0" }}>
                      <th
                        style={{
                          textAlign: "left",
                          padding: "10px",
                          width: "40%",
                        }}
                      >
                        Account Name
                      </th>
                      {/* <th>Location</th> */}
                      <th>Account Number</th>
                      <th style={{ textAlign: "right", padding: "10px" }}>
                        Amount
                      </th>
                    </tr>
                    {RenderMethod("Bank")}
                  </table>
                  <br></br>
                  <table className="account_table">
                    <tr style={{ background: "lightgray" }}>
                      <th
                        colSpan={4}
                        style={{ padding: "10px", textAlign: "center" }}
                      >
                        Mobile Banking
                      </th>
                    </tr>
                    <tr style={{ background: "#f0f0f0" }}>
                      <th
                        style={{
                          textAlign: "left",
                          padding: "10px",
                          width: "40%",
                        }}
                      >
                        Account Name
                      </th>
                      {/* <th>Location</th> */}
                      <th>Account Number</th>
                      <th style={{ textAlign: "right", padding: "10px" }}>
                        Amount
                      </th>
                    </tr>
                    {RenderMethod("Mobile banking")}
                  </table>
                  {/* <table className="history_table">
                    <tr>
                      <td>Date</td>
                      <td>Particular</td>
                      <td>Narration</td>
                      <td>Amount</td>
                    </tr>

                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        <b>Opening Balance</b>
                      </td>
                      <td>
                        <b>{openingbalance.current}</b>
                      </td>
                    </tr>

                    {journals.map((item, index) => {
                      if (
                        new Date(start.current).getTime() -
                          new Date(item.created).getTime() <
                        0
                      ) {
                        return (
                          <>
                            <tr>
                              <td>
                                {moment(item.created).format("DD-MM-YYYY")}
                              </td>
                              <td>{item.Subgroup}</td>
                              <td>{item.details}</td>
                              <td>
                                {item.type == "Credit"
                                  ? "(" + item.amount + ")"
                                  : item.amount}
                              </td>
                            </tr>
                          </>
                        );
                      }
                    })}

                    <tr>
                      <td colSpan={3}>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          {endingBalance.current < 0
                            ? "(" + endingBalance.current * -1 + ")"
                            : endingBalance.current}
                        </b>
                      </td>
                    </tr>
                  </table> */}
                  <Row style={{ minHeight: "60px", marginTop: "40px" }}>
                    <Col span={12} style={{ textAlign: "left" }}>
                      {/* <img
                        src={businessprofile.signature}
                        style={{
                          maxHeight: "60px",
                          left: "0",
                        }}
                      /> */}
                    </Col>
                    <Col span={12} style={{ textAlign: "center" }}></Col>
                  </Row>
                  <Row>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <h3
                        style={{
                          borderTop: "2px solid black",
                          display: "inline-block",
                        }}
                      >
                        ACCOUNT SIGNATURE
                      </h3>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <h3
                        style={{
                          borderTop: "2px solid black",
                          display: "inline-block",
                        }}
                      >
                        AUTHORIZER SIGNATURE
                      </h3>
                    </Col>
                  </Row>
                </div>
              </Spin>
            ) : (
              "Please select a date"
            )}
          </Col>
        </Row>
      </>
    );
  };
  const RenderAccounts = (typetotal, type, account) => {
    return journals.map((item) => {
      if (item.Subgroup == type && account.id == item.account) {
        if (item.increase) {
          typetotal += parseFloat(item.amount);
        } else {
          typetotal -= parseFloat(item.amount);
        }
        return (
          <>
            <p>
              {account.name} / {item.Subgroup} / {item.type} / {item.amount} /{" "}
              {item.details}
            </p>
          </>
        );
      }
    });
  };

  const RenderMethod = (type) => {
    let typetotal = 0;
    if (type == "Cash") {
      totalBranch.current = 0;
      for (let i = 0; i < locationlist.length; i++) {
        totalBranch.current += parseFloat(locationlist[i].cash);
      }
      // console.log(totalBranch.current);
      return (
        <>
          {accounts.map((account) => {
            if (type == account.type) {
              let accountTotal = 0;
              journals.map((item) => {
                if (item.Subgroup == type && account.id == item.account) {
                  console.log(item.details);
                  if (item.increase) {
                    typetotal += parseFloat(item.amount);
                    accountTotal += parseFloat(item.amount);
                  } else {
                    typetotal -= parseFloat(item.amount);
                    accountTotal -= parseFloat(item.amount);
                  }
                }
              });
              return (
                <>
                  <tr>
                    <td style={{ textAlign: "left" }}>{account.name}</td>
                    {/* <td>All</td> */}
                    <td>{account.account_no}</td>
                    <td style={{ textAlign: "right" }}>
                      {parseFloat(typetotal - totalBranch.current).toFixed(2)}
                    </td>
                  </tr>
                </>
              );
            }
          })}
          {locationlist?.map((location) => {
            if (location.cash > 0) {
              return (
                <>
                  <tr>
                    <td style={{ textAlign: "left" }}>{location.name}</td>
                    {/* <td>All</td> */}
                    <td></td>
                    <td style={{ textAlign: "right" }}>
                      {parseFloat(location.cash).toFixed(2)}
                    </td>
                  </tr>
                </>
              );
            }
          })}
          <tr>
            <td style={{ textAlign: "left" }} colSpan={2}>
              <b>Total</b>
            </td>

            <td style={{ textAlign: "right" }}>
              <b>{parseFloat(typetotal).toFixed(2)}</b>
            </td>
          </tr>
        </>
      );
    } else {
      return (
        <>
          {accounts.map((account) => {
            if (type == account.type) {
              let accountTotal = 0;
              journals.map((item) => {
                if (item.Subgroup == "Bank" && account.id == item.account) {
                  if (item.increase) {
                    typetotal += parseFloat(item.amount);
                    accountTotal += parseFloat(item.amount);
                  } else {
                    typetotal -= parseFloat(item.amount);
                    accountTotal -= parseFloat(item.amount);
                  }
                }
              });
              return (
                <>
                  <tr>
                    <td style={{ textAlign: "left" }}>{account.name}</td>
                    {/* <td>All</td> */}
                    <td>{account.account_no}</td>
                    <td style={{ textAlign: "right" }}>
                      {parseFloat(accountTotal).toFixed(2)}
                    </td>
                  </tr>
                </>
              );
            }
          })}

          <tr>
            <td style={{ textAlign: "left" }} colSpan={2}>
              <b>Total</b>
            </td>

            <td style={{ textAlign: "right" }}>
              <b>{parseFloat(typetotal).toFixed(2)}</b>
            </td>
          </tr>
        </>
      );
    }
  };

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Cash & Bank Ledger</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              {Rendercontent()}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
    locationlist: state.warehouse.locationlist,
  };
};

export default connect(mapStateToProps, {
  getJournalSearchResult,
  getAllAccount,
  getAlljournalvoucheritemsByAccount,
  getAllreceivevoucheritemsByAccount,
  getAllpaymentvoucheritemByAccount,
  getAllInvoicesByAccount,
  getAllPayslipByAccount,
  getAllLocation,
})(AccountsStatus);
