import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Spin, Select } from "antd";

import {
  getAllEmployeeLoan,
  getAllEmployeeLoanPagination,
} from "../../../actions/loanManagementAction";
import Rendertable from "./Rendertable";

const EmployeeLeaveList = ({
  getAllEmployeeLoan,
  getAllEmployeeLoanPagination,
  employeeLoanlist,
  reload,
  setreload,
  setData,
  data,
  pageno,
  page_size,
  keyward,
  applicationstatus,
  loanstatus,
  type,
}) => {
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    getAllEmployeeLoanPagination(
      keyward.current,
      applicationstatus.current,
      loanstatus.current,
      type.current,
      pageno.current,
      page_size.current
    ).then((result) => {
      setData(result);
      setloading(false);
    });
  }, [reload]);
  return (
    <>
      {
        <Spin spinning={loading}>
          <Rendertable
            List={data}
            reload={reload}
            setreload={setreload}
            pageno={pageno}
            page_size={page_size}
          />
        </Spin>
      }
    </>
  );
};

export default connect(null, {
  getAllEmployeeLoan,
  getAllEmployeeLoanPagination,
})(EmployeeLeaveList);
