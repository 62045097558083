import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import CategoryList from "./CategoryList";
import CreateNewAttribute from "./CreateNewCategory";
import EditCategory from "./EditCategory";
import { deleteCategory } from "../../../actions/categoryAction";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Divider,
  Space,
  Popconfirm,
  Button,
  message,
} from "antd";

const { Content } = Layout;

const Category = ({ deleteCategory }) => {
  const [updatelist, setUpdatelist] = useState(true);
  const [categorylist, setcategorylist] = useState([]);
  const [selectedCategory, setselectedCategory] = useState([]);

  const confirm = () => {
    deleteCategory(selectedCategory.id);
    setUpdatelist(false);
    message.success(
      selectedCategory.name + " Has been deleted from your categories"
    );
  };

  const renderDetails = () => {
    if (selectedCategory.name) {
      return (
        <>
          <Row>
            <Col span={8}>
              <h4>Category Name :</h4>
            </Col>
            <Col span={16}>
              <p>{selectedCategory.name}</p>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <h4>Slug :</h4>
            </Col>
            <Col span={16}>
              <p>{selectedCategory.slug}</p>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <h4>Meta title :</h4>
            </Col>
            <Col span={16}>
              <p>{selectedCategory.meta_title}</p>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <h4>Meta Description :</h4>
            </Col>
            <Col span={16}>
              <p>{selectedCategory.meta_description}</p>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <h4>Page title :</h4>
            </Col>
            <Col span={16}>
              <p>{selectedCategory.page_title}</p>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <h4>Page Description :</h4>
            </Col>
            <Col span={16}>
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedCategory.page_description,
                }}
              ></div>
            </Col>
          </Row>
          <Divider></Divider>
          <Space>
            <Button danger style={{ marginRight: "10px" }}>
              <Popconfirm
                title="Are you sure to delete this category?"
                onConfirm={confirm}
                okText="Yes"
                cancelText="No"
              >
                <Link to="#">Delete</Link>
              </Popconfirm>
            </Button>
            <EditCategory
              details={selectedCategory}
              setUpdatelist={setUpdatelist}
            />
          </Space>
        </>
      );
    } else {
      return <p>Please select a category to see details</p>;
    }
  };

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Category</Breadcrumb.Item>
              <Breadcrumb.Item>All</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              <Row>
                <Col span={12}>
                  <CreateNewAttribute
                    setUpdatelist={setUpdatelist}
                    updatelist={updatelist}
                  />
                  <h3>Category list</h3>
                  <Divider></Divider>
                  <CategoryList
                    updatelist={updatelist}
                    setUpdatelist={setUpdatelist}
                    setcategorylist={setcategorylist}
                    setselectedCategory={setselectedCategory}
                  />
                </Col>
                <Col span={10} offset={2}>
                  <h3>Category Details</h3>
                  <Divider></Divider>
                  {renderDetails()}
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default connect(null, { deleteCategory })(Category);
