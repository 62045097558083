import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import ContactList from "./ContactList";
import CreateNewContct from "./CreateNewContct";
import { getcontacttype } from "../../actions/settings";
import {
  Row,
  Col,
  DatePicker,
  Space,
  Select,
  Button,
  AutoComplete,
  Divider,
} from "antd";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Maincontent = ({ getcontacttype, Types }) => {
  const [updatelist, setUpdatelist] = useState(true);
  const start = useRef("");
  const end = useRef("");
  const role = useRef("");
  const keyward = useRef("");
  const pageno = useRef(1);
  const page_size = useRef(20);

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
      setUpdatelist(!updatelist);
    }
  };

  useEffect(() => {
    getcontacttype();
  }, []);

  const SwitchablePicker = () => {
    return (
      <Row wrap={false}>
        <Col flex="auto">
          keyword :
          <AutoComplete
            placeholder="input search text"
            // onChange={onChange}
            onKeyUp={onChange}
            style={{ width: "100%", minWidth: "140px" }}
          />
        </Col>
        <Col flex="auto">
          Issue date :<br></br>
          <RangePicker
            style={{ width: "100%" }}
            picker="date"
            onChange={(value) => {
              if (value) {
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                setUpdatelist(!updatelist);
              }
            }}
          />
        </Col>
        <Col flex="auto">
          Type :
          <Select
            style={{ width: "100%" }}
            placeholder="Status"
            onChange={(value) => {
              role.current = value;
              pageno.current = 1;
              setUpdatelist(!updatelist);
            }}
          >
            <Option value="">All</Option>
            {Types.map((type) => {
              if (type.Type == "Customer") {
                return <Option value={type.id}>{type.name}</Option>;
              }
            })}
          </Select>
        </Col>

        <Col flex="auto">
          .
          <CreateNewContct
            setUpdatelist={setUpdatelist}
            updatelist={updatelist}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="site-layout-background main-frame">
        {SwitchablePicker()}
        <Divider />
        <ContactList
          updatelist={updatelist}
          setUpdatelist={setUpdatelist}
          pageno={pageno}
          page_size={page_size}
          start={start}
          end={end}
          keyward={keyward}
          role={role}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Types: state.contacts.contacttype,
  };
};

export default connect(mapStateToProps, { getcontacttype })(Maincontent);
