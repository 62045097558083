import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Drawer,
  TreeSelect,
  Button,
  Col,
  Row,
  Select,
  TimePicker,
  message,
  DatePicker,
  Upload,
  Layout,
  Breadcrumb,
  Skeleton,
  Divider,
  Spin,
} from "antd";
import ImageUpload from "./ImageUpload";
import {
  getBusinessProfile,
  updateBusinessProfile,
} from "../../../actions/settings";
import { createimage } from "../../../actions/ecommerceimageAction";
const { Content } = Layout;

const BusinessProfile = ({ createimage, updatelist, setUpdatelist }) => {
  const [visible, setVisible] = useState(false);
  const [data, setdata] = useState();
  const [Logo, setLogo] = useState(null);
  const [Signature, setSignature] = useState(null);
  const [imgFileBase64, setimgFileBase64] = useState(null);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setVisible(true);
    values["data"] = "";
    const formData = new FormData();
    buildFormData(formData, values);
    if (Logo != null && Logo != undefined) {
      formData.append("image", Logo);
      formData.append("thumbnail", Logo);
    }

    createimage(formData).then((e) => {
      setVisible(false);
      setUpdatelist(!updatelist);
      form.resetFields();
    });
  };
  const details = () => {
    return (
      <>
        <Form
          form={form}
          layout="horizontal"
          // labelCol={{ span: 6 }}
          // wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        // initialValues={businessprofile}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="logoo" extra="Upload new image">
                <ImageUpload
                  setFile={(file) => setLogo(file)}
                  setFileBase64={(file) => setimgFileBase64(file)}
                >
                  <Button icon={<UploadOutlined />}> Click to Upload</Button>
                </ImageUpload>
              </Form.Item>
            </Col>
            <Row>
              <Col span={12}>
                <Form.Item name="alt">
                  <Input placeholder="Alternative text" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Upload
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Form>
      </>
    );
  };
  return (
    <>
      <Spin spinning={visible}>{details()}</Spin>
    </>
  );
};

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

export default connect(null, {
  createimage,
})(BusinessProfile);
