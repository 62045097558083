import * as types from "../types";
import backend from "../api/api";
import history from "../history";
import { store } from "../store";
import { message } from "antd";

const getConfig = () => {
  const token = store.getState().auth.token;

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return config;
};

export const createfeatureitem = (formValues) => async (dispatch) => {
  try {
    const response = await backend.post(
      `api/ecommerce/featureitem/`,
      formValues,
      getConfig()
    );
    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    alert(error);
  }
};

export const getAllfeatureitem = () => async (dispatch) => {
  try {
    const response = await backend.get(
      "api/ecommerce/featureitem/",
      getConfig()
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSpecificfeatureitem = (id) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/ecommerce/featureitem/${id}/`,
      getConfig()
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updatefeatureitem = (id, formValues) => async (dispatch) => {
  try {
    const response = await backend.put(
      `api/ecommerce/featureitem/${id}/`,
      formValues,
      getConfig()
    );
    if (response.status === 202) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deletefeatureitem = (id) => async (dispatch) => {
  try {
    const response = await backend.delete(
      `api/ecommerce/featureitem/${id}/`,
      getConfig()
    );
    if (response.status === 204) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};
